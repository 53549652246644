import React,{useState,useEffect} from 'react'
import { getAllItems, getDateTime } from '../../utils/firebaseFunctions';
import {doc,getDocs,orderBy,setDoc,collection,query,where,deleteDoc, updateDoc,} from "firebase/firestore"
import { firestore } from '../../firebase.config';
import UpdateFormation from '../update/UpdateFormation';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import StepContent from '@mui/material/StepContent';

const MainFormationsBenevolesLesson = () => {
    const [isExecuted,setExecuted] = useState(false)
    const [training,setTraining] = useState([])
    const [chapters,setChapters] = useState([])
    const [article,setArticle] = useState()
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const [userTrain,setUserTrain] = useState({})
    const isStepOptional = (step) => {
      return step === 1;
    };
  
    const isStepSkipped = (step) => {
      return skipped.has(step);
    };
  
    const handleNext = () => {
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }
  
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const handleSkip = () => {
      if (!isStepOptional(activeStep)) {
        // You probably want to guard against something like this,
        // it should never occur unless someone's actively trying to break something.
        throw new Error("You can't skip a step that isn't optional.");
      }
  
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped((prevSkipped) => {
        const newSkipped = new Set(prevSkipped.values());
        newSkipped.add(activeStep);
        return newSkipped;
      });
    };
  
    const handleReset = () => {
      setActiveStep(0);
    };
  
    const [updateDateMode,setUpdateMode] = useState(false)    
    const fetchData = async () =>{
        await  getAllItems("formations","date","desc").then(
             (data) =>{
                 for(let i = 0;i<data.length;i++){
                  if(data[i].date === JSON.parse(localStorage.getItem("CURRENT_TRAINING")))
                  {
                      setTraining(data[i])  
                  }
                 }
                 
             }
          
         )
         await  getAllItems("chapters","date","asc").then(
          (data) =>{
              setChapters(data.filter(d=>d.formationId === JSON.parse(localStorage.getItem("CURRENT_TRAINING")))) 
          }       
      )

      let d = await getData()
      setUserTrain(d)
      setActiveStep(d.chapters)
      }
    const history = useNavigate()



 
    useEffect(
        ()=>{
        if(isExecuted)
        {
            return
        }
        if(!localStorage.getItem("CURRENT_TRAINING")){
          history("/")
        }

        fetchData()
        setExecuted(true)
        },[]
    )
    const getData = async () =>{
      const q = query(collection(firestore, "trainings"), where("date", "==", JSON.parse(localStorage.getItem("trainingId"))));
      const table = []
      let data = {}
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        data = doc.data()
        table.push(data)
      });
  
  
      return table[0]
    }
   
    return (
        <div className="p-6 mb-6 bg-slate-50 min-h-screen">
                    <div className="ext-zinc-900 text-3xl font-medium leading-normal mb-8">{training.title}</div>
                    {/* <DataGrid
        rows={activities}
        columns={columns}
        getRowId={(row) => row.date}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection
      /> */}


<Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep}>
        {chapters?.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
       
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step  key={label.date} {...stepProps}>
              <StepLabel className='text-orange-500' {...labelProps}>{label.title}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === chapters.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            <div className='flex flex-col gap-5 justify-center items-center mt-10'>
            <img className='w-[400px]' src ="/congratulation.png"/>
            <h1 style={{fontSize:"25px",letterSpacing:"2px"}}>Training Completed</h1>
            </div>
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            {/* <Button onClick={handleReset}>Reset</Button> */}
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Typography  sx={{ mt: 3, mb: 3 }}><h1 style = {{fontWeight:"500",letterSpacing:"2px"}}>Chapter {activeStep + 1}: {chapters[activeStep]?.title.toString()}</h1></Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            {/* <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button> */}
            <Box sx={{ flex: '1 1 auto' }} />
            {/* {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )} */}

            {/* <Button onClick={handleNext}>
              {activeStep === chapters.length - 1 ? 'Finish' : 'Next'}
            </Button> */}
          </Box>
        </React.Fragment>
      )}
    </Box>
    {activeStep !== chapters.length &&
    <Lesson currentChapter={activeStep+1} onActiveStep = {setActiveStep} isLast = {activeStep+1  === chapters.length}  steps={chapters[activeStep]?.questions} description={chapters[activeStep]?.content}/>
       }   </div >
    )
}

const Lesson = ({currentChapter,isLast, steps,description,onActiveStep}) =>{
  const [activeStep, setActiveStep] = React.useState(0);
  const getData = async () =>{
    const q = query(collection(firestore, "trainings"), where("date", "==", JSON.parse(localStorage.getItem("trainingId"))));
    const table = []
    let data = {}
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      data = doc.data()
      table.push(doc.id)
    });


    return table[0]
  }
  const updateData = async (chapters) =>{
      let u = await getData()
      const ref = doc(firestore,"trainings",u)
      await updateDoc(ref,{chapters:chapters}).then(()=>{
      }).catch(
        (err)=>{
      
        }
      )
    }
  const handleNext = () => {
    if(activeStep>0){
    if(!currentResp && !steps[activeStep-1].res){
      alert('choose an answer !')
      return
    }  
    steps[activeStep-1].res = currentResp
    setCurrentResp("")
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if((activeStep === steps?.length) && returnResult().length === 0)
    {
    
    updateData(currentChapter)
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const fixResponse = (value) =>{
    steps[activeStep-1].res = value
    setCurrentResp(value)
  }
  const handleReset = () => {
    setActiveStep(0);
  };
  const returnResult = () =>{
    let res = []
    for(let i = 0;i<steps.length;i++){
        if(steps[i].response !== steps[i].res){
            res.push(`Question ${i+1}: Wrong answer `)
        }  
    }
    return res
  }
  const goToNextChapter = () => {
      setActiveStep(0)
      onActiveStep(value=>value+1)
      for(let i = 0;i<steps.length;i++){
      delete steps[i].res  }
  }
  const [currentResp,setCurrentResp] = useState("")
  return <Box sx={{ }}>
      <Stepper activeStep={activeStep} orientation="vertical">
      <Step key={"fgd"}>
            <StepLabel
              
            >
              {}
            </StepLabel>
            <StepContent>
              <Typography>{activeStep === 0? "Content":`Question ${activeStep}`} {description}</Typography>
              {/* <div>
                {
                  step?.questions?.map(
                    (s)=>{
                      return <div> <input type="radio"/></div>
                    }
                  )
                }
              </div> */}
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Continue
                  </Button>
                  <Button
                    disabled={0 === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        {steps?.map((step, index) => (
          <Step key={index}>
            <StepLabel
              optional={
                index === steps?.length+1 ? (
                  <Typography variant="caption">Last step</Typography>
                ) : null
              }
            >
              {step.label}
            </StepLabel>
            <StepContent>
              <Typography><span style = {{fontWeight:"bold"}}>{activeStep === 0? "Content":`Question ${activeStep}: `} </span> {step.title} {activeStep>0 && <p className='mb-3 text-sm'>Choose the correct answer</p>}</Typography>
               <div>
                {
                  step?.answers?.map(
                    (s)=>{
                      return <div> <input checked = {step.res === s} value={s} onChange={(e)=>fixResponse(e.target.value)} type="checkbox" name = {step.title}/> {s}</div>
                    }
                  )
                }
              </div> 
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {index === steps?.length  ? 'Finish' : 'Continue'}
                  </Button>
                  <Button
                    
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>

      {activeStep === steps?.length + 1 && (
        <>
        
        <Paper square elevation={0} sx={{ p: 3 }}>
          {
            returnResult().length > 0 && returnResult().map(
              (v)=>{
                return <Typography>{v}</Typography>
              }
            )
          }
         {returnResult().length > 0 ? <Typography className='ml-5'>Please try again</Typography> :<Typography className='ml-5'>   All steps completed - you&apos;re finished</Typography>}
          {returnResult().length > 0?   <Button
                    
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button>: !isLast? <Button onClick={()=>goToNextChapter()}>Next chapter</Button>:<Button onClick={()=>goToNextChapter()}>
training completed</Button>}
          {/* <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button> */}
        </Paper>
        </>
      )}
    </Box>
}
export default MainFormationsBenevolesLesson