import React,{useContext,useEffect,useState} from 'react'
import Navbar from "../../components/admin-navbar/admin-navbar"
import Sidebar from "../../components/adminn-sidebar/sidebar"
import { Context } from '../../context'
import Main from "../../components/admin-main/main"
import { useParams } from 'react-router-dom'
import { Data } from '../../dates/jummy'
import MainFormations from '../../components/admin-main/mzinFormations'
import AddFormation from '../../components/add/AddFormation'
import MainMission from '../../components/admin-main/mainMission'
import AddMission from '../../components/add/AddMission'
import MainUsers from '../../components/admin-main/MainUsers'
import AddUserMission from '../../components/admin-items/missions'
import AllMissions from '../../components/admin-items/allmissions'
import Rapport from '../../components/admin-items/Rapportd'
import AddUser from '../../components/add/AddUser'


export default function Users() {
  const {category} = useParams()
  let { state, dispatch } = useContext(Context)
  let [size, setSize] = useState(1000)
  window.addEventListener('resize', (e) => {
    setSize(e.currentTarget.innerWidth)
  })
  useEffect(() => {
    size < 768 ? dispatch({ type: 'SET_TOGGLE_NAVBAR', payload: false }) : dispatch({ type: 'SET_TOGGLE_NAVBAR', payload: true })
  }, [size])
  return (
    <div className='bg-slate-50'>
      <div className=""><Navbar /></div>
      <div className="main max-w-[2300px] mt-[76px] flex flex-1 justify-between">
        <Sidebar active='fa-solid fa-users'subActive ="Utilisateurs" />
        <div className={`main ${state.toggle ? ` ${state.toggleNavbar ? 'md:ml-[310px]' : 'ml-0'}` : ` ${state.toggleNavbar ? 'md:ml-[90px]' : 'ml-0'}`} overflow-auto w-full h-full z-10`}>
          {
            category === 'data' && <MainUsers/>
          }
          {
            category === 'add' && <AddUser/>
          }
                    {
            category === "add-mission" && <AddUserMission/> 
          }    
          {
            category ==="missions" && <AllMissions/>
          }
          {
            category === "rapports" && <Rapport/>
          }    
        </div>
      </div>
    </div>
  )
}
