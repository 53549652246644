import React,{useEffect,useState} from 'react';
import Paper from '@mui/material/Paper';
import { ViewState } from '@devexpress/dx-react-scheduler';
import {doc,getDocs,Timestamp, orderBy,setDoc,collection,query, where, updateDoc} from "firebase/firestore"
import { firestore } from '../../firebase.config';
import {
  Scheduler,
  MonthView,
  Toolbar,
  DateNavigator,
  Appointments,
  TodayButton,
} from '@devexpress/dx-react-scheduler-material-ui';
import { getAllItems } from '../../utils/firebaseFunctions';
import { Button, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useNavigate } from 'react-router-dom';


 const appointments = [
  {
    title: 'Website Re-Design Plan',
    startDate: new Date(2018, 6, 23, 9, 30),
    endDate: new Date(2018, 6, 23, 11, 30),
  }, {
    title: 'Book Flights to San Fran for Sales Trip',
    startDate: new Date(2018, 6, 23, 12, 0),
    endDate: new Date(2018, 6, 23, 13, 0),
  }, {
    title: 'Install New Router in Dev Room',
    startDate: new Date(2018, 6, 23, 14, 30),
    endDate: new Date(2018, 6, 23, 15, 30),
  }, {
    title: 'Approve Personal Computer Upgrade Plan',
    startDate: new Date(2018, 6, 24, 10, 0),
    endDate: new Date(2018, 6, 24, 11, 0),
  }, {
    title: 'Final Budget Review',
    startDate: new Date(2018, 6, 24, 12, 0),
    endDate: new Date(2018, 6, 24, 13, 35),
  }, {
    title: 'New Brochures',
    startDate: new Date(2018, 6, 24, 14, 30),
    endDate: new Date(2018, 6, 24, 15, 45),
  }, {
    title: 'Install New Database',
    startDate: new Date(2018, 6, 25, 9, 45),
    endDate: new Date(2018, 6, 25, 11, 15),
  }, {
    title: 'Approve New Online Marketing Strategy',
    startDate: new Date(2018, 6, 25, 12, 0),
    endDate: new Date(2018, 6, 25, 14, 0),
  }, {
    title: 'Upgrade Personal Computers',
    startDate: new Date(2018, 6, 25, 15, 15),
    endDate: new Date(2018, 6, 25, 16, 30),
  }, {
    title: 'Customer Workshop',
    startDate: new Date(2018, 6, 26, 11, 0),
    endDate: new Date(2018, 6, 26, 12, 0),
  }, {
    title: 'Prepare 2015 Marketing Plan',
    startDate: new Date(2018, 6, 26, 11, 0),
    endDate: new Date(2018, 6, 26, 13, 30),
  }, {
    title: 'Brochure Design Review',
    startDate: new Date(2018, 6, 26, 14, 0),
    endDate: new Date(2018, 6, 26, 15, 30),
  }, {
    title: 'Create Icons for Website',
    startDate: new Date(2018, 6, 27, 10, 0),
    endDate: new Date(2018, 6, 27, 11, 30),
  }, {
    title: 'Upgrade Server Hardware',
    startDate: new Date(2018, 6, 27, 14, 30),
    endDate: new Date(2018, 6, 27, 16, 0),
  }, {
    title: 'Submit New Website Design',
    startDate: new Date(2018, 6, 27, 16, 30),
    endDate: new Date(2018, 6, 27, 18, 0),
  }, {
    title: 'Launch New Website',
    startDate: new Date(2018, 6, 26, 12, 20),
    endDate: new Date(2018, 6, 26, 14, 0),
  }, {
    title: 'Website Re-Design Plan',
    startDate: new Date(2018, 6, 16, 9, 30),
    endDate: new Date(2018, 6, 16, 15, 30),
  }, {
    title: 'Book Flights to San Fran for Sales Trip',
    startDate: new Date(2018, 6, 16, 12, 0),
    endDate: new Date(2018, 6, 16, 13, 0),
  }, {
    title: 'Install New Database',
    startDate: new Date(2018, 6, 17, 15, 45),
    endDate: new Date(2018, 6, 18, 12, 15),
  }, {
    title: 'Approve New Online Marketing Strategy',
    startDate: new Date(2018, 6, 18, 12, 35),
    endDate: new Date(2018, 6, 18, 14, 15),
  }, {
    title: 'Upgrade Personal Computers',
    startDate: new Date(2018, 6, 19, 15, 15),
    endDate: new Date(2018, 6, 20, 20, 30),
  }, {
    title: 'Prepare 2015 Marketing Plan',
    startDate: new Date(2018, 6, 20, 20, 0),
    endDate: new Date(2018, 6, 20, 13, 30),
  }, {
    title: 'Brochure Design Review',
    startDate: new Date(2018, 6, 20, 14, 10),
    endDate: new Date(2018, 6, 20, 15, 30),
  }, {
    title: 'Vacation',
    startDate: new Date(2018, 5, 22),
    endDate: new Date(2018, 6, 1),
  }, {
    title: 'Vacation',
    startDate: new Date(2018, 6, 28),
    endDate: new Date(2018, 7, 7),
  },
];

export default function RapportB() {
  

   const today = () =>{
    var date = new Date();

// Obtenir l'année
var year = date.getFullYear();

// Obtenir le mois
// Les mois sont indexés de 0 à 11, donc nous devons ajouter 1
var month = (date.getMonth() + 1).toString().padStart(2, '0');

// Obtenir le jour
var day = date.getDate().toString().padStart(2, '0');

// Format de la date "année-mois-jour"
var formattedDate = year + '-' + month + '-' + day;

// Afficher la date
    return formattedDate
   }
   const [report ,setReport] = useState("")
   const [data,setData] = useState([])
   const [isExecuted,setIsExecuted] = useState(false) 
   const fetchData = async () =>{
    await  getAllItems("rapports","date","desc").then(
         (data) =>{
            let d = []
            let filteredData = data.filter(d=>(d.missionId == JSON.parse(localStorage.getItem("ASYNC_ID"))))
             for(let i = 0;i<filteredData.length;i++){
                 d.push({
                  title: filteredData[i].message,
                  startDate: new Date(filteredData[i].date-1000*60*60*24),
                  endDate: new Date(filteredData[i].date),
                 })
             }
             setData(d) 
         }
      
     )
  }

  const updateData = async (data,date,table) =>{
    const q = query(collection(firestore, table), where("date", "==", date));
    const tables = []
    const querySnapshot = await getDocs(q); 
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      tables.push(doc.id)
    
    });
    const ref = doc(firestore,table,tables[0])
    await updateDoc(ref,data).then(()=>{
   
    }).catch(
      (err)=>{
        console.log(err)
        alert(err)
      }
    )
    
  }
  const history = useNavigate()
  const setFinished = async () => {
    await updateData({status:"Terminé"},parseInt(JSON.parse(localStorage.getItem("ASYNC_ID"))),"usermission")
    alert("project completed")        
    history("/missions-benevoles/data")
  } 
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const saveDetails = async () =>{
    try{
          
             if(!report){
                alert("field empty!")
                return
             }
                
                
                // const images = JSON.parse(localStorage.getItem("images"))
                const data = {
                    id:(new Date().getTime()).toString(),
                    date:new Date().getTime(),
                    message:report,
                    missionId:JSON.parse(localStorage.getItem("ASYNC_ID"))

                }
                try{
                    // for(let i = 0;i<images.length;i++)
                    // {
                    //     await setDoc(doc(firestore,"images",`${Date.now()}`),images[i],{merge:true})
                    // }
                    await setDoc(doc(firestore,"rapports",`${Date.now()}`),data,{merge:true}).catch((error)=>{alert(error)})
                    setReport("")
                    window.location.reload()
                }
                catch(error)
                {
                    alert(error)
                }
    }
    catch(error)
    {
    alert("Error")
    }
}
   useEffect(
    ()=>{
        if(isExecuted)
        {
          return
        }
        fetchData()
        setIsExecuted(true)
    },[]
   )
    return (
      <>

      <Paper>
      <div className='p-5 relative'>
           <div className='flex'> 
              <Button onClick={handleOpen} variant='contained'>Add Report</Button>
              <div className='pl-2'></div>
              <Button onClick={()=>setFinished()} variant='outlined'>Set project to done</Button>
           </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <Box sx={style}>
          <div onClick={handleClose} className='absolute cursor-pointer top-5 right-5'>X</div>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add report
          </Typography>
         <div className='p-3'></div>

         <TextField onChange={(e)=>setReport(e.target.value)} value={report} multiline minRows={7} className='w-full' placeholder='report'/>
         <div className='p-3'></div>
         <Button onClick={()=>saveDetails()} variant='contained'>Add</Button>
        </Box>
      </Modal>
    </div>

       
        <Scheduler
          data={data}
        >
          <ViewState
            defaultCurrentDate={today().toString()}
          />
          <MonthView />
          <Toolbar />
          <DateNavigator />
          <TodayButton />
          <Appointments />
        </Scheduler>
      </Paper>
      </>
    );
  
}
