import React,{useEffect,useState} from 'react'
import {doc,getDocs,orderBy,setDoc,collection,query,where,deleteDoc, updateDoc,} from "firebase/firestore"
import { firestore } from '../../firebase.config';
import { getAllItems } from '../../utils/firebaseFunctions';
import { Link } from 'react-router-dom';

const Cards = ({onAdmin,onUsers,onBenevoles,onFormations,onArticles,onActivities,onMissions}) => {
    
    const [blog,setBlog] = useState(0)
    const [activities,setActivities] = useState(0)
    const [mission,setMission] = useState(0)
    const [community,setCommunity] = useState(0)
    const [pblog,setpBlog] = useState(0)
    const [pactivities,setpActivities] = useState(0)
    const [pmission,setpMission] = useState(0)
    const [pcommunity,setpCommunity] = useState(0)
    const [isExecuted,setIsExecuted] = useState(false)
    const fetchData = async () =>{
        await  getAllItems("blog","date","desc").then(
             (data) =>{
                 setBlog(data.length)
                 onArticles(data.length) 
             }
          
         )
         await  getAllItems("users","date","desc").then(
            (data) =>{
                 if(data.length>0){
                    onAdmin(data.filter(d=>d.status==="admin").length)
                    onBenevoles(data.filter(d=>d.status==="bénévole").length)
                    onUsers(data.filter(d=>d.status==="utilisateur").length)
                    setpCommunity((data.filter(d=>d.status === "bénévole").length/data.length)*100)
                 }
                setCommunity(data.length) 
            }
         
        )
        await  getAllItems("activities","date","desc").then(
            (data) =>{
                setActivities(data.length)
                onActivities(data.length)
                if(data.length>0){
                    setpActivities((data.filter(d=>d.status === "En cours").length/data.length)*100)

                } 
            }
         
        )
        await  getAllItems("usermission","date","desc").then(
            (data) =>{
                setMission(data.length) 
                onMissions(data.length)
                if(data.length>0){
                    setpMission((data.filter(d=>d.status === "Terminé").length/data.length)*100)

                }      
            }
         
        )

        await  getAllItems("formations","date","desc").then(
            (data) =>{
                onFormations(data.length) 
                
            }
         
        )
      } 
    const printData = (value) =>{
        let data = parseFloat(value)
        if(data>999){
            return data/1000 + "K"
        }
        else{
            return data
        }
     }
     let cards = [
        {
            text: 'Missions',
            icon: 'fa-solid fa-business-time',
            iconColor: 'text-neutral-500',
            money: mission,
            percent: pmission.toFixed(2)+"%",
            percentColor: 'text-lime-500',
            trend: "fa-solid fa-arrow-up",
            status:"Terminées",
            link:"/missions/data"
        },
        {
            text: 'Communauté',
            icon: 'fa-solid fa-users',
            iconColor: 'text-neutral-500',
            money: community,
            percent: pcommunity.toFixed(2)+"%",
            percentColor: 'text-lime-500',
            trend: "fa-solid fa-arrow-up",
            status:"de Bénévoles",
            link:"/users/data"

        },
        {
            text: 'Articles',
            icon: 'fa-solid fa-pen-nib',
            iconColor: 'text-neutral-500',
            money: blog,
            percent: "",
            percentColor: 'text-lime-500',
            trend: "fa-solid fa-arrow-up",
            status:"",
            link:"/blog/data"

        },
        {
            text: 'Activités',
            icon: 'fa-solid fa-chart-simple',
            iconColor: 'text-orange-600',
            money: activities,
            percent: pactivities.toFixed(2)+"%",
            percentColor: 'text-orange-600',
            trend: "fa-solid fa-arrow-down",
            status:"En cours",
            link:"/activities/data"
        }
    ]
    useEffect(
        ()=>{
            if(isExecuted){
                return
            }
                fetchData()
            setIsExecuted(true)
        },[]
    )
    return (
        <div className=" justify-start w-full items-start flex-wrap xl:flex-nowrap gap-4 inline-flex">
            {cards.map(({ text,link, icon, money, percent, percentColor, trend, iconColor,status }, index) => {
                return (
                    <div key={index} className="min-w-[220px] xl:w-full grow shrink basis-0 rounded-xl drop-shadow justify-start items-start gap-4 bg-white flex">
                        <div className="grow shrink basis-0 bg-white rounded-xl flex-col justify-start items-start inline-flex">
                            <div className="w-full h-[164px] px-6 pt-6 pb-4 bg-white rounded-xl shadow flex-col justify-start items-start gap-6 flex">
                                <div className="self-stretch justify-start items-center gap-3 inline-flex">
                                    <div className={`w-6 h-6 relative ${iconColor}`}><i className={icon}></i></div>
                                    <div className="grow shrink basis-0 text-zinc-900 text-base font-medium leading-normal">{text}</div>
                                </div>
                                <div className="self-stretch h-[72px] flex-col justify-center items-start gap-1 flex">
                                    <div className="text-zinc-900 text-[40px] font-semibold leading-[48px]">{printData(money)}</div>
                                    <div className="h-5 relative flex gap-2">
                                        <div className={`${percentColor} flex items-center`}>
                                            <div className="">
                                                <div className="text-sm font-semibold leading-tight" >{percent}</div>
                                            </div>
                                        </div>
                                        <div className=" text-zinc-500 text-xs font-medium leading-[18px]">{status}</div>
                                    </div>
                                </div>
                            </div>
                            <Link to = {link}>
                            <div  className=" w-full px-6 py-4 justify-between items-center gap-4 inline-flex">
                                <div className="grow shrink basis-0 text-orange-600 text-sm font-semibold leading-tight">Vour tout</div>
                                <i class="fa-solid fa-chevron-right text-orange-600"></i>
                            </div>
                            </Link>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default Cards