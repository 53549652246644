import React,{useState,useEffect} from 'react'
import { DataGrid } from '@mui/x-data-grid';
import { getAllItems } from '../../utils/firebaseFunctions';
import UpdateArticle from '../update/UpdateArticle';
import {doc,getDocs,orderBy,setDoc,collection,query,where,deleteDoc, updateDoc,} from "firebase/firestore"
import { firestore } from '../../firebase.config';

const MainCategoriesBlog = () => {
    const [isExecuted,setExecuted] = useState(false)
    const [blog,setBlog] = useState([])
    const [article,setArticle] = useState()
    const [updateDateMode,setUpdateMode] = useState(false)
    
    const fetchData = async () =>{
        await  getAllItems("cb","date","desc").then(
             (data) =>{
                 setBlog(data) 
             }
          
         )
      }

    const columns = [
        { field: 'id', headerName: 'ID', width: 70,renderCell:({row:{date}})=>{
            return ( <h4>#{date}</h4>)
        } },
       
        
        { field: 'name', headerName: 'Nom', width: 130 },
      
        {
          field: 'Actions',
          headerName: 'Actions',
          sortable: false,
          width: 260,
          renderCell: (params) =>{
            return <button onClick={()=>deleteById(params.row.date)} className='text-red-500 bg-red-100 px-3 py-2 rounded-md'>Supprimer</button>
        }, 
    }
      ];

      const deleteById = async (id) =>{
        const q = query(collection(firestore, "cb"), where("date", "==", id));
        const table = []
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          table.push(doc.id)
        });
        var answer = window.confirm("Voulez vous vraiment supprimer?");
        if (answer) {
            await deleteDoc(doc(firestore,"cb",table[0]))
            alert("suppression terminée")
            fetchData()
        }
        else {
        //some code
        }
      }
    useEffect(
        ()=>{
        if(isExecuted)
        {
            return
        }
        fetchData()
        
        },[]
    )


    return (
        <div className="p-6 mb-6 bg-slate-50 min-h-screen">
                    <div className="ext-zinc-900 text-3xl font-medium leading-normal mb-8">Catégories</div>
                    <DataGrid
        rows={blog}
        columns={columns}
        getRowId={(row) => row.date}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection
      />


        </div >
    )
}

export default MainCategoriesBlog