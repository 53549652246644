import React,{useState,useEffect} from 'react'
import { TextField,InputAdornment } from '@mui/material'
import {Button} from '@mui/material'
import {firestore} from "../../firebase.config"
import {doc,getDocs,orderBy,setDoc,collection,query,where,deleteDoc, updateDoc,} from "firebase/firestore"
import { Link, useNavigate } from 'react-router-dom'
import { getAuth, sendPasswordResetEmail, signInWithEmailAndPassword } from "firebase/auth";
import { auth } from '../../firebase.config'
import { MdMail, MdPassword } from 'react-icons/md'
export default function ForgetPassword() {
    const [email,setEmail] =  useState()
    const [password,setPassword] = useState()
    const history = useNavigate()
    const handleSubmit = async () => {
    
        try {
            await sendPasswordResetEmail(auth, email).then((a) => {
                alert("Password reset email sent")
                history("/login")
              })
          
        } catch (error) {
          
            alert("Something is wrong, please try again!");
        }
      };
    useEffect(
        ()=>{
        
        }
    ) 
    return (
    <div className='min-h-screen h-auto p-0 md:p-10 bg-gray-100 grid grid-cols-1 md:grid-cols-2'>
        <div className='p-[45px] w-full bg-white rounded-tl-lg rounded-bl-lg grid-cols-1'>
            <img className=' h-32' src ="logo.png"/>
            <h1 className='font-bold text-[20px] text-start mb-1'>CHANGE PASSWORD</h1>
            <h3 className='mb-5 text-[#555]'>you will receive a link that will allow you to reset your password</h3>
        
            <TextField  InputProps={{startAdornment: (
    <InputAdornment position="end">
      <MdMail />
    </InputAdornment>
)}} onChange={(e)=>setEmail(e.target.value)} value={email} id="email" label="Email*"  className='w-full' variant="outlined" />
            <div className='py-2'></div>
                    <div className='mt-3'></div>
                <Button onClick={()=>handleSubmit()} className='w-full' variant="contained">RESET PASSWORD</Button>
            <h3 className='mb-10 text-[#555] mt-5'>Do you have account ?<Link to ="/login"> <span className='text-orange-500 cursor-pointer'>login</span></Link></h3>
            
        </div>
        <div  style={{backgroundImage:"url(https://images.pexels.com/photos/6348129/pexels-photo-6348129.jpeg?auto=compress&cs=tinysrgb&w=600)",backgroundRepeat:"no-repeat",backgroundSize:"cover",backgroundPosition:"center"}} className='hidden md:block md:grid-cols-1 h-[100%] bg-orange-50 rounded-tr-lg rounded-br-lg relative'>
            <div className='rounded-tr-lg rounded-br-lg absolute top-0 left-0 right-0 bottom-0 bg-gradient-to-r from-orange-400 opacity-10 '>

            </div>
        </div>
    </div>
  )
}
