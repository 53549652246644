import React,{useContext,useEffect,useState} from 'react'
import Navbar from "../../components/admin-navbar/admin-navbar"
import Sidebar from "../../components/adminn-sidebar/sidebar"
import { Context } from '../../context'
import Main from "../../components/admin-main/main"
import { useNavigate, useParams } from 'react-router-dom'
import { Data } from '../../dates/jummy'
import Marketing from "../../components/admin-marketing/admin-marketing"
import MainBlog from '../../components/admin-main/MainBlog'
import AddBlog from '../../components/add/AddArticle'
import MainCategoriesBlog from '../../components/admin-main/mainCategoriesBlog'
import AddCategory from '../../components/add/AddCategory'
import SidebarB from '../../components/adminn-sidebar/sidebarbenevoles'
import { Button, TextField } from '@mui/material'
import {doc,getDocs,Timestamp, orderBy,setDoc,collection,query, where, updateDoc} from "firebase/firestore"
import { firestore } from '../../firebase.config';
export default function ProfilB() {
  const {category} = useParams()
  let { state, dispatch } = useContext(Context)

  const [firstName,setFirstName] = useState("")
  const [lastName,setLastName] = useState("")
  const [contact,setContact] = useState("")
  const [address,setAddress]= useState("")
  const [status,setStatus] = useState("")
  const [image,setImage] = useState("")

  const [newFirstName,setNewFirstName] = useState("")
  const [newLastName,setNewLastName] = useState("")
  const [newContact,setNewContact] = useState("")
  const [newAddress,setNewAddress]= useState("")
  const [newStatus,setNewStatus] = useState("")
  const [newImage,setNewImage] = useState("")
  const initValues = () =>{
    let user = JSON.parse(localStorage.getItem("USER"))
    setFirstName(user.firstName)
    setLastName(user.lastName)
    setContact(user.contact)
    setStatus(user.status)
    setAddress(user.address)
    setImage(user.img)
  }
  let [size, setSize] = useState(1000)
  window.addEventListener('resize', (e) => {
    setSize(e.currentTarget.innerWidth)
  })
  const updateData = async (data,date,table) =>{
    const q = query(collection(firestore, table), where("date", "==", date));
    const tables = []
    const querySnapshot = await getDocs(q); 
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      tables.push(doc.id)
    
    });
    const ref = doc(firestore,table,tables[0])
    await updateDoc(ref,data).then(()=>{
   
    }).catch(
      (err)=>{
        console.log(err)
        alert(err)
      }
    )
    
  }
  const history = useNavigate()
  const updateProfil = async () => {
    let currentUser = JSON.parse(localStorage.getItem("USER"))
    let data = {}
    if(!newAddress && !newFirstName && !newLastName && !newContact){
        alert("please fill in at least one field")
        return
    }
    if(newAddress){
        data["address"] = newAddress  
        currentUser.address = newAddress
    }
    if(newFirstName){
        data["firstName"] = newFirstName
        currentUser.firstName = newFirstName

    }
    if(newLastName){
        data["lastName"] = newLastName  
        currentUser.lastName = newLastName

    }
    if(newContact){
        data["contact"] = newContact
        currentUser.contact = newContact

    }

    await updateData(data,currentUser.date,"users").then(
        (data)=>{
            localStorage.setItem("USER",JSON.stringify(currentUser))
            alert("update successfuly")        
            window.location.reload()
        }
    ).catch(
        (error)=>{
            alert("Something is wrong, please try again!")
        }
    )
  
  } 
  useEffect(() => {
    initValues()
    size < 768 ? dispatch({ type: 'SET_TOGGLE_NAVBAR', payload: false }) : dispatch({ type: 'SET_TOGGLE_NAVBAR', payload: true })
  }, [size])
  return (
    <div className='bg-slate-50'>
      <div className=""><Navbar /></div>
      <div className="main max-w-[2300px] mt-[76px] flex flex-1 justify-between">
        <SidebarB active='fa-solid fa-user'subActive ="Profile" />
        <div className={`main ${state.toggle ? ` ${state.toggleNavbar ? 'md:ml-[310px]' : 'ml-0'}` : ` ${state.toggleNavbar ? 'md:ml-[90px]' : 'ml-0'}`} overflow-auto w-full h-full z-10`}>
        <div className="p-6 mb-6 bg-slate-50 min-h-screen">
                    <div className="ext-zinc-900 text-3xl font-medium leading-normal mb-8">Update Your Profil</div>
                    <div className='grid grid-cols-2 gap-5'>
                        <div className='flex flex-col items-center  rounded-lg gap-2 justify-between bg-white p-10'>
                            <div>
                                <img src = {image} className='w-40  h-40 rounded-full'/>      
                            </div>
                            <div className='flex flex-col items-start'>
                                <p className='flex gap-3 text-[#666]'><span className=' text-black font-bold'>First Name: </span>{firstName}</p>
                                <p className='flex gap-3 text-[#666]'><span className=' text-black font-bold'>Last Name: </span>{lastName}</p>
                                <p className='flex gap-3 text-[#666]'><span className='text-black font-bold'>Statut: </span>{status}</p>
                                <p className='flex gap-3 text-[#666]'><span className='text-black font-bold'>Address: </span>{address}</p>
                                <p className='flex gap-3 text-[#666]'><span className='text-black font-bold'>Contact: </span>{contact}</p>
                            </div>
                        </div>
                        <div className='rounded-lg gap-2 bg-white p-10'>
                        {/* <div className='flex flex-col items-start mb-10'>
                                <p className='flex gap-3 text-[#666]'><span className=' text-black font-bold'>Mission(s) an attente(s): </span>{missions.filter(m=>m.status === "En attente").length}</p>
                                <p className='flex gap-3 text-[#666]'><span className=' text-black font-bold'>Mission(s) en cours: </span>{missions.filter(m=>m.status === "En cours").length}</p>
                                <p className='flex gap-3 text-[#666]'><span className='text-black font-bold'>Mission(s) terminée(s): </span>{missions.filter(m=>m.status === "Terminé").length}</p>
                                <p className='flex gap-3 text-[#666]'><span className='text-black font-bold'>Total: </span>{missions.length}</p>
                            </div> */}
                        <TextField placeholder='Enter new first name' className='w-full' value={newFirstName} onChange={(e)=>setNewFirstName(e.target.value)}/>            
                        <p className='p-2'></p>

                        <TextField placeholder='Enter new last name' className='w-full' value={newLastName} onChange={(e)=>setNewLastName(e.target.value)}/>            
                        <p className='p-2'></p>

                        <TextField placeholder='Enter new address' className='w-full' value={newAddress} onChange={(e)=>setNewAddress(e.target.value)}/>            
                        <p className='p-2'></p>

                        <TextField placeholder='Enter new contact' className='w-full' value={newContact} onChange={(e)=>setNewContact(e.target.value)}/>            
                        <p className='p-2'></p>

                        <Button onClick={()=>updateProfil()} variant='contained'>Update</Button>
                     
                        </div>
                       
                    </div>
                 
        
        </div >
        </div>
      </div>
    </div>
  )
}
