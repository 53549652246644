import React,{useContext,useEffect,useState} from 'react'
import Navbar from "../../components/admin-navbar/admin-navbar"
import Sidebar from "../../components/adminn-sidebar/sidebar"
import { Context } from '../../context'
import Main from "../../components/admin-main/main"
import { useParams } from 'react-router-dom'
import { Data } from '../../dates/jummy'
import Marketing from "../../components/admin-marketing/admin-marketing"
import BenevoleMain from '../../components/admin-main/benevole_main'
import SidebarB from '../../components/adminn-sidebar/sidebarbenevoles'

export default function BenevolesDashboardHome() {
  const {category} = useParams()
  let { state, dispatch } = useContext(Context)
  let [size, setSize] = useState(1000)
  window.addEventListener('resize', (e) => {
    setSize(e.currentTarget.innerWidth)
  })
  useEffect(() => {
    size < 768 ? dispatch({ type: 'SET_TOGGLE_NAVBAR', payload: false }) : dispatch({ type: 'SET_TOGGLE_NAVBAR', payload: true })
  }, [size])
  return (
    <div className='bg-slate-50'>
      <div className=""><Navbar /></div>
      <div className="main max-w-[2300px] mt-[76px] flex flex-1 justify-between">
        <SidebarB />
        <div className={`main ${state.toggle ? ` ${state.toggleNavbar ? 'md:ml-[310px]' : 'ml-0'}` : ` ${state.toggleNavbar ? 'md:ml-[90px]' : 'ml-0'}`} overflow-auto w-full h-full z-10`}>
           <BenevoleMain />
          
     
        </div>
      </div>
    </div>
  )
}
