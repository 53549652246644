import React,{useEffect} from 'react'
import { Route,Routes,useNavigate } from 'react-router-dom'
import AdminDashboard from "./pages/dashboard-home/DashboardHome"
import Blog from './pages/blog/Blog'
import Activities from './pages/activities/Activities'
import Formations from './pages/formations/Formations'
import Missions from './pages/missions/missions'
import Users from './pages/users/users'
import Notifications from './pages/notifications/notifications'
import Login from './pages/login/login'
import SecondLogin from './pages/secondLogin/seond_login'
import Register from './pages/register/seond_register'
import AddChapter from './components/admin-items/chapter'
import BenevolesDashboardHome from './pages/dashboard-home/BenevolesDashboardHome'
import NotificationsBenevole from './pages/notifications/notifications_benevole'
import MissionsB from './pages/missions/missions_benevoles'
import ProfilB from './pages/profil/profil_screen'
import ForgetPassword from './pages/forget password/forget_password'
import FormationsBenevoles from './pages/formations/Formations_benevoles'

export default function App() {
  const history = useNavigate()
  
  useEffect(
    ()=>{
   
      
       if(!localStorage.getItem("USER")){
         history("/login")
         }
         else if(localStorage.getItem("USER") && JSON.parse(localStorage.getItem("USER"))["status"] === "admin"){
           history("/")     
         }
         else if(localStorage.getItem("USER") && JSON.parse(localStorage.getItem("USER"))["status"] !== "admin"){
      
          history("/") 
         }
       
    },[]
  )
  return (
    <div className='h-screen w-full'>
        <Routes>
            <Route path ="/login" element ={<SecondLogin/>}/>
            <Route path = "/register" element={<Register/>}/>
            <Route path ="/forget-password" element = {<ForgetPassword/>}/>
            {
                (localStorage.getItem("USER") && JSON.parse(localStorage.getItem("USER"))["status"] !== "admin") &&  <>
                    <Route path ="/" element = {<BenevolesDashboardHome/>}/> 
                    <Route path ="/notifications-benevoles/:category" element={<NotificationsBenevole/>}/>
                    <Route path ="/missions-benevoles/:category" element = {<MissionsB/>}/>
                    <Route path = "/profile" element = {<ProfilB/>}/>
                    <Route path = "/formations-benevoles/:category" element = {<FormationsBenevoles/>}/>

                </>  
            }



    {    (localStorage.getItem("USER") && JSON.parse(localStorage.getItem("USER"))["status"] === "admin") &&   <> 
            <Route path ="/" element = {<AdminDashboard/>}/>    
            <Route path ="/blog/:category" element = {<Blog/>}/>
            <Route path ="/activities/:category" element = {<Activities/>}/>
            <Route path ="/formations/:category" element = {<Formations/>}/>
            <Route path ="/missions/:category" element = {<Missions/>}/>
            <Route path ="/users/:category" element = {<Users/>}/>
            <Route path ="/notifications/:category" element={<Notifications/>}/>

          </>}
        </Routes>  
    </div>
  )
}
