import React,{useContext,useEffect,useState} from 'react'
import { Context } from '../../context'

export default function Navbar() {
   let {state,dispatch} = useContext(Context)
   let toggle = () => {
       dispatch({type:"SET_TOGGLE_NAVBAR",payload:!state.toggleNavbar})
   }
  const [user,setUser] = useState()
  useEffect(
    ()=>{
        setUser(JSON.parse(localStorage.getItem("USER")))
    },[]
  )
  return (
    <div className='z-50 fixed left-0 right-0 top-0 h-[76px] px-6 py-6 bg-white border-b  border-neutral-200 flex justify-between items-center gap-5'>
        <div className='flex gap-6 items-center'>
           <div className='border-r pr-6 border-[#e4e4e4]'>
               <img src ="/logo.png" className='h-[75px]'/>  
           </div>
           <div onClick={toggle} className='text-xl font-semibold leading-8 text-[#19191c]'>
               Dashboard
           </div>
        </div>
        <div className='justify-start items-center gap-8 flex'>
            <div className='hidden rounded-[50px] border border-neutral-200 justify-start items-start md:flex'>
                <div className='px-8 py-2.5 hover:bg-neutral-200 rounded-l-full duration-300 items-center gap-2 flex'>
                    <div className='h-10 w-10 relative'>
                        <div className='w-full h-full absolute top-0.5' >
                            <img className='rounded-full h-10 w-10' src ={user?.img}/>
                        </div> 
                    </div> 
                    <h1 className='text-zinc-900 text-base'>{user?.firstName}</h1> 
                </div>
                <div className='px-8 py-2.5 hover:bg-neutral-200 rounded-l-full duration-300 items-center gap-2 flex'>
                    <div className='h-10 w-10 relative'>
                        <div className='w-full h-full' >
                            <img className='rounded-full' src ="/bell.png"/>
                        </div> 
                        <div className='absolute -top-2 -right-3 text-white w-7 h-7 rounded-full bg-red-400 flex items-center justify-center'>
                           2
                        </div>
                    </div> 
                 
                </div>
            </div>
        </div>
    </div>
  )
}
