import React,{useEffect, useState} from 'react'
import { DataGrid } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { MenuItem } from '@mui/material';
import { MdOutlineAddPhotoAlternate } from "react-icons/md";
import { getDownloadURL, uploadBytesResumable,ref, deleteObject } from 'firebase/storage'
import { storage } from '../../firebase.config';
import {doc,getDocs,where,updateDoc, Timestamp, orderBy,setDoc,collection,query} from "firebase/firestore"
import { firestore } from '../../firebase.config';
// Firebase237Firebase237*/
const UpdateActivity = ({onUpdateArticle}) => {
   const [isLoading,setIsLoading] = useState(false)
   const [imageAsset,setImageAsset] = useState("")
   const [currentImageAsset,setCurrentImageAsset] = useState("")
   const [title,setTitle] = useState("")
   const [description,setDescription] = useState("")
   const [status,setStatus] = useState("")
   const [isExecuted,setIsExecuted] = useState(false)
   const getData = async () =>{
    const q = query(collection(firestore, "activities"), where("date", "==", JSON.parse(localStorage.getItem("ASYNC_ID"))));
    const table = []
    let data = {}
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      data = doc.data()
      table.push(doc.id)
    });

    setTitle(data.title)
    setDescription(data.description)
    setStatus(data.status)
    setCurrentImageAsset(data.img)

    return table[0]
  }

  const updateData = async () =>{
    if(!title || !description || !status)
    {
      alert("Veillez remplir tous les champs avant d'enregistrer")
    }
    else{
      let u = await getData()
      const ref = doc(firestore,"activities",u)
      await updateDoc(ref,{title,status,img:imageAsset?imageAsset:currentImageAsset,description}).then(()=>{
         alert("Modification terminé")
         localStorage.removeItem("ASYNC_ID")
         onUpdateArticle(false)
      }).catch(
        (err)=>{
          console.log(err)
          alert(err)
        }
      )
   
    
    }
  }
   const uploadImage = async (e) =>{      
        setIsLoading(true)
        if(imageAsset){
        await deleteObject(ref(storage,imageAsset))
        }
        if(!e.target.files[0])
        {
            return 
        }
        const imageFile  = e.target.files[0]
        const {type} = e.target.files[0]
        const storageRef = ref(storage,`${Date.now()}-${imageFile.name}`)
        const uploadTask = uploadBytesResumable(storageRef,imageFile)
        const image = JSON.parse(localStorage.getItem("CURRENT_IMAGE"))
        uploadTask.on(
            "state_changed",
            (snapshot) =>{
                const uploadProgress = (snapshot.bytesTransferred/snapshot.totalBytes)*100
            },
            (error)=>{
                console.log(error)
                alert("Une erreur s'est produite")
                setIsLoading(false)
            
            },
            ()=>{
                getDownloadURL(uploadTask.snapshot.ref).then((downloadUrl)=>{
                    setImageAsset(downloadUrl);
                    setIsLoading(false)
                    alert("Fichier ajouté au courrier 🙂 ")
                    localStorage.setItem("CURRENT_IMAGE",JSON.stringify(downloadUrl))                               
                })
            }
        )
    
    }
const cancelUpdate = () =>{
    onUpdateArticle(false)
    localStorage.removeItem("UPDATED_IMAGE")
}
   useEffect(
    ()=>{
        if(isExecuted)
        {
            return
        }
        if(localStorage.getItem("UPDATED_IMAGE")){
            setImageAsset(JSON.parse(localStorage.getItem("UPDATED_IMAGE")))
        }
        getData()
        setIsExecuted(true)
    },[]
   )
    return (
        <div className="p-6 mb-6 bg-slate-50 min-h-screen">
                    <div className="ext-zinc-900 text-3xl font-medium leading-normal mb-8">Modifier l'activité</div>
                    <div className='grid grid-cols-2 gap-5'>
                        <div className='rounded-lg gap-2 bg-white p-10'>
                        <TextField id="title" onChange={(e)=>setTitle(e.target.value)} value={title} label="Titre de l'article*" className='w-full mb-5' variant="outlined" />
                        <div className='p-2'></div>
                        <TextField onChange={(e)=>setDescription(e.target.value)} value={description} id="title" label="Description*" minRows={5} multiline className='w-full' variant="outlined" />
                        <div className='p-2'></div>
                        
                        <TextField
          id="outlined-select-currency"
          select
          className='w-full'
          label="categorie"
          defaultValue="EUR"
          helperText="Veillez choisir la catégorie"
          onChange={(e)=>setStatus(e.target.value)}
          value = {status} 
        >
          {["En cours","Suspendu"].map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
                        </div>
                        <div className='rounder-lg bg-white p-10'>
                          <label htmlFor='image' className='bg-gray-100 cursor-pointer w-full p-5 flex justify-center text-center rounded-md'>
                          {(!imageAsset && !currentImageAsset) && <MdOutlineAddPhotoAlternate size={200}/>}
                          {(imageAsset || currentImageAsset) && <img src = {imageAsset? imageAsset:currentImageAsset} alt ="img" className='w-full h-[300px] rounded-md'/>}
                          <input type='file' id ="image" className='hidden' onChange={uploadImage}/>
                          </label>
                          <div className='p-2'></div>
                          <div className='flex gap-2 items-center'>
                          <Button onClick={()=>updateData()}  className='mt-5' variant='contained'>Modifier</Button>
                          <Button onClick={()=>cancelUpdate()}  className='mt-5' >annuler</Button>
                       
                          </div>
                       </div>
                    </div>

        </div >
    )
}

export default UpdateActivity