import React,{useEffect, useState} from 'react'
import { DataGrid } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { MenuItem } from '@mui/material';
import { MdDelete, MdOutlineAddPhotoAlternate } from "react-icons/md";
import { getDownloadURL, uploadBytesResumable,ref, deleteObject } from 'firebase/storage'
import { storage } from '../../firebase.config';
import {doc,getDocs,where,updateDoc, Timestamp, orderBy,setDoc,collection,query,deleteDoc} from "firebase/firestore"
import { firestore } from '../../firebase.config';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import { getAllItems } from '../../utils/firebaseFunctions';
import AddMission from '../add/AddMission';
import { useNavigate } from 'react-router-dom';
// Firebase237Firebase237*/
const AddChapter = ({onUpdateArticle}) => {
   const [isLoading,setIsLoading] = useState(false)
   const [data,setData] = useState([])
   const history = useNavigate()
   const [isHover,setIsHover] = useState(false)
   const [content,setContent] = useState("")
   const [currentQuestion,setCurrentQuestion] = useState("")
   const [response,setResponse] = useState("")
   const [tags,setTags] = useState([])
   const [firstName,setFirstName] = useState("")
   const [lastName,setLastName] = useState("")
   const [currentAnswer,setCurrentAnswer] = useState("")
   const [missions,setMissions] = useState([])
   const [questions,setQuestions] = useState([])
   const [status,setStatus] = useState("")
   const [isExecuted,setIsExecuted] = useState(false)
   const [address,setAddress] = useState("")
   const [contact,setContact] = useState(0)
   const [title,setTitle] = useState("")
   const [image,setImage] = useState("")
   const deleteById = async (id) =>{
    const q = query(collection(firestore, "chapters"), where("date", "==", id));
    const table = []
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      table.push(doc.id)
    });
    var answer = window.confirm("Voulez vous vraiment supprimer?");
    if (answer) {
        await deleteDoc(doc(firestore,"chapters",table[0]))
        alert("suppression terminée")
        fetchData()
    }
    else {
    //some code
    }
  }
 const addAnswer = () =>{
     if(!currentAnswer){
         alert("veillez remplir le champs!")  
     }
     else{
         setTags([...tags,currentAnswer])
         setCurrentAnswer("") 
     }
     
 }

 const addQuestion = () =>{
     if(!currentQuestion || tags.length === 0|| !response){
         alert("Veillez remplir tous les champs et choisir une réponse avant l'enregistrement!") 
     }
     else{
      setQuestions([...questions,{title:currentQuestion,answers:tags,response:response}])
      setCurrentQuestion("")
      setResponse("")
      setTags([]) 
    }

 }
  const goToRapport = (id) => {
    history("/users/rapports")
    localStorage.setItem("ASYNC_ID",JSON.stringify(id))    } 
   const columns = [
    { field: 'id', headerName: 'ID', width: 70,renderCell:({row:{date}})=>{
        return ( <h4>#{date}</h4>)
    } },
    { field: 'title', headerName: 'Titre', width: 130 },
    { field: 'content', headerName: 'Contenu', width: 130 },
    {
      field: 'questions',
      headerName: 'Questions',
      renderCell:({row:{questions}})=>{
        return ( <h4>{questions.length}</h4>)
    },
      width: 100,
    },
    {
      field: 'Actions',
      headerName: 'Actions',
      sortable: false,
      flex:1,
      renderCell: (params) =>{
        return <div className='flex gap-2 justify-between items-center'><button   className='text-blue-500 bg-blue-100 px-3 py-2 rounded-md'>Modifier</button> <button onClick={()=>deleteById(params.row.date)} className='text-red-500 bg-red-100 px-3 py-2 rounded-md'>Supprimer</button>  </div>
    }, 
}
  ];
  const fetchData = async () =>{
    await  getAllItems("chapters","date","asc").then(
         (data) =>{
             setData(data) 
         }
      
     )
  }
   const saveMission = async () =>{
    
    if(!title || !content || questions.length === 0){
        alert("veillez remplir tous les champs!")
        return
    } 
    try{
        setIsLoading(true)
    alert("okay")      
        
                
                // const images = JSON.parse(localStorage.getItem("images"))
                const data = {
                    questions,
                    content,
                    title,
                    date:new Date().getTime(),
                    formationId:JSON.parse(localStorage.getItem("ASYNC_ID"))
                }
                try{
                    // for(let i = 0;i<images.length;i++)
                    // {
                    //     await setDoc(doc(firestore,"images",`${Date.now()}`),images[i],{merge:true})
                    // }
                    await setDoc(doc(firestore,"chapters",`${Date.now()}`),data,{merge:true}).then(
                        (data)=>{
                            alert("Opération terminée!")
                            fetchData()
                            setContent("")
                            setCurrentQuestion("")
                            setTags([])
                            setQuestions([])
                        }
                    )

               

                     
                }
                catch(error)
                {
                    alert(error)
                }
                setIsLoading(false)
    }
    catch(error)
    {
    alert("Error")
    }

   }
   const deleteQuestion = (title) => {
        const data = questions.filter(q => q.title !== title)
        setQuestions(data)
   }

   const getData = async () =>{
    const q = query(collection(firestore, "chapters"), where("formationId", "==", JSON.parse(localStorage.getItem("ASYNC_ID"))));
    const table = []
    let data = {}
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      data = doc.data()
      table.push(doc.id)
    });

    setFirstName(data.firstName)
    setImage(data.img)
    setLastName(data.lastName)
    setStatus(data.status)
    setContact(data.contact)
    setAddress(data.address)
    return table[0]
  }


  const fetchMission = async () =>{
    await  getAllItems("missions","date","desc").then(
         (data) =>{
             setMissions(data) 
         }
     )
  }

   useEffect(
    ()=>{
        if(isExecuted)
        {
            return
        }
   
        
        // fetchMission()
        fetchData()
        setIsExecuted(true)
    },[]
   )
    return (
        <div className="p-6 mb-6 bg-slate-50 min-h-screen">
                    <div className="ext-zinc-900 text-3xl font-medium leading-normal mb-8">Ajouter un chapitre à votre formation</div>
                    <div className='grid grid-cols-2 gap-5'>
                        <div className='flex flex-col rounded-lg gap-2 items-start justify-between bg-white p-10'>
                          
                            <TextField className='w-full' value={title} placeholder='Titre*' onChange={(e)=>setTitle(e.target.value)}/>
                            <div className='p-1'></div>
                            <TextField placeholder='contenu' value ={content} onChange={(e)=>setContent(e.target.value)} className='w-full' multiline minRows={10}>
                              
                            </TextField>
                        </div>
                        <div className='rounded-lg gap-2 bg-white p-10'>
                          <h2 className='font-bold mb-2'>Questions du test</h2>
                          <TextField value={currentQuestion} onChange={(e)=>setCurrentQuestion(e.target.value)} className='w-full'  placeholder='Intitulé de la question'/>
                          <div className='p-1'></div>
                          <div className='flex gap-2'>
                            <TextField value={currentAnswer} onChange={(e)=>setCurrentAnswer(e.target.value)} className='w-full' placeholder='Ajoutez une réponse'/>  
                            <Button onClick={()=>addAnswer()} variant ="contained"><span className ='text-2xl'>+</span></Button>
                             
                          </div>
                          <div className='flex gap-2 flex-wrap'>
                            {
                              
                            }
                             <div className='flex flex-col justify-start items-start mt-5'>
                              {
                                
                                tags.length > 0 && tags.map(
                                  (tag)=>{
                                    return( 
                                    <div  className='flex gap-2'><input onClick={()=>setResponse(tag)} type ="radio" name ="answer"/> {<span>{tag}</span>}</div>)
                                  }
                                )
                              }
                              </div>
                          </div>
                          
                       
                        <div className='flex gap-2 mt-5'>
                   <Button variant ="contained" onClick={addQuestion}>Ajouter</Button>
    
                   </div>
                
                        </div>
                       
                    </div>
                   <div className='p-2'></div>
                   { questions.length > 0 &&  <h1 className='font-bold mb-2'>Questions</h1>}
                   <div className='flex flex-wrap gap-2'>
                       {
                           questions.map(
                            (q)=>{
                              return <div onMouseLeave={()=>setIsHover(false)} onMouseEnter={()=>setIsHover(true)} className ="relative" style={{background:"white",padding:"13px",cursor:"pointer",borderRadius:"10px"}}>{isHover && <div onClick={()=>deleteQuestion(q.title)} className='absolute right-1 top-1'>{<MdDelete/>}</div>} {q.title}</div>
                            }
                           ) 
                       }
                   </div>
                   {
                    questions.length > 0 && (<>
                      <div className='p-5'></div>
                      <Button onClick ={()=>saveMission()} variant ="contained">Ajouter le chapitre</Button>
                      </>
                    )
                   }
                   <div className="mt-6 mb-6 bg-slate-50 min-h-screen">
                    <div className="ext-zinc-900 text-3xl font-medium leading-normal mb-8">Chapitres</div>
                    <DataGrid
        rows={data}
        columns={columns}
        getRowId={(row) => row.date}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection
      />


        </div >
        </div >
    )
}

export default AddChapter