import React,{useEffect, useState} from 'react'
import { DataGrid } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { MenuItem } from '@mui/material';
import { MdOutlineAddPhotoAlternate } from "react-icons/md";
import { getDownloadURL, uploadBytesResumable,ref, deleteObject } from 'firebase/storage'
import { storage } from '../../firebase.config';
import {doc,getDocs,Timestamp, orderBy,setDoc,collection,query} from "firebase/firestore"
import { firestore } from '../../firebase.config';
import { getAllItems } from '../../utils/firebaseFunctions';

// Firebase237Firebase237*/
const AddActivity = () => {
   const [isLoading,setIsLoading] = useState(false)
   const [imageAsset,setImageAsset] = useState("")
   const [title,setTitle] = useState("")
   const [description,setDescription] = useState("")
   const [isExecuted,setIsExecuted] = useState("")
   const [starting,setStarting] = useState(new Date())
   const [address,setAddress] = useState("")
   const [contact,setContact] = useState("")

   const saveDetails = async () =>{
    try{
          
             if(!address||!imageAsset||!description||!title||!contact){
                alert("Veillez remplir tous les champs")
                return
             }
                
                
                // const images = JSON.parse(localStorage.getItem("images"))
                const data = {
                    title,
                    description,
                    img:imageAsset,
                    date:new Date().getTime(),
                    contact,
                    address,
                    status:"En cours"
                }
                try{
                    // for(let i = 0;i<images.length;i++)
                    // {
                    //     await setDoc(doc(firestore,"images",`${Date.now()}`),images[i],{merge:true})
                    // }
                    await setDoc(doc(firestore,"activities",`${Date.now()}`),data,{merge:true}).catch((error)=>{alert(error)})
                    localStorage.removeItem("CURRENT_IMAGE")
                    setDescription("")
                    setImageAsset("")
                    setStarting(null)
                    setAddress("")
                    setTitle("")
                }
                catch(error)
                {
                    alert(error)
                }
    }
    catch(error)
    {
    alert("Error")
    }
}
   const uploadImage = async (e) =>{      
        setIsLoading(true)
        if(imageAsset){
        await deleteObject(ref(storage,imageAsset))
        }
        if(!e.target.files[0])
        {
            return 
        }
        const imageFile  = e.target.files[0]
        const {type} = e.target.files[0]
        const storageRef = ref(storage,`${Date.now()}-${imageFile.name}`)
        const uploadTask = uploadBytesResumable(storageRef,imageFile)
        const image = JSON.parse(localStorage.getItem("CURRENT_IMAGE"))
        uploadTask.on(
            "state_changed",
            (snapshot) =>{
                const uploadProgress = (snapshot.bytesTransferred/snapshot.totalBytes)*100
            },
            (error)=>{
                console.log(error)
                alert("Une erreur s'est produite")
                setIsLoading(false)
            
            },
            ()=>{
                getDownloadURL(uploadTask.snapshot.ref).then((downloadUrl)=>{
                    setImageAsset(downloadUrl);
                    setIsLoading(false)
                    alert("Image ajouté 🙂 ")
                    localStorage.setItem("CURRENT_IMAGE",JSON.stringify(downloadUrl))                               
                })
            }
        )
    
    }
   useEffect(
    ()=>{
        if(isExecuted)
        {
            return
        }
        if(localStorage.getItem("CURRENT_IMAGE")){
            setImageAsset(JSON.parse(localStorage.getItem("CURRENT_IMAGE")))
        }
        setIsExecuted(true)
    },[]
   )
    return (
        <div className="p-6 mb-6 bg-slate-50 min-h-screen">
                    <div className="ext-zinc-900 text-3xl font-medium leading-normal mb-8">Ajouter une activité</div>
                    <div className='grid grid-cols-2 gap-5'>
                        <div className='rounded-lg gap-2 bg-white p-10'>
                        <TextField id="title" onChange={(e)=>setTitle(e.target.value)} value={title} label="Titre de l'article*" className='w-full mb-5' variant="outlined" />
                        <div className='p-2'></div>
                        <TextField onChange={(e)=>setDescription(e.target.value)} value={description} id="description" label="Description*" minRows={5} multiline className='w-full' variant="outlined" />
                        <div className='p-2'></div>
                        <TextField onChange={(e)=>setAddress(e.target.value)} value={address} id="address" label="Address*"  className='w-full' variant="outlined" />
                        <div className='p-2'></div>
                        <TextField onChange={(e)=>setContact(e.target.value)} value={contact} id="contact" label="Contact*"  className='w-full' variant="outlined" />
                        <div className='p-2'></div>
                        </div>
                    
                        <div className='rounder-lg bg-white p-10'>
                          <label htmlFor='image' className='bg-gray-100 cursor-pointer w-full p-5 flex justify-center text-center rounded-md'>
                          {!imageAsset && <MdOutlineAddPhotoAlternate size={200}/>}
                          {imageAsset && <img src = {imageAsset} alt ="img" className='w-full h-[300px] rounded-md'/>}
                          <input type='file' id ="image" className='hidden' onChange={uploadImage}/>
                          </label>
                          <div className='p-2'></div>
                          <Button onClick={saveDetails} className='mt-5' variant='contained'>Enregistrer</Button>
                       </div>
                    </div>

        </div >
    )
}

export default AddActivity