import React,{useEffect,useState} from 'react'
import { dateStaff } from '../../dates/jummy'
import {doc,getDocs,orderBy,setDoc,collection,query,where,deleteDoc, updateDoc,} from "firebase/firestore"
import { firestore } from '../../firebase.config';
import { getAllItems, getDateTime } from '../../utils/firebaseFunctions';
import { Link } from 'react-router-dom';
const Staff = () => {
    const [users,setUsers] = useState(new Array())
    const [isExecuted,setIsExecuted] = useState(false)
    const fetchData = async () =>{
        await  getAllItems("users","date","desc").then(
             (data) =>{
                 setUsers(data.slice(0,10)) 
             }
          
         )
    }
    useEffect(
        ()=>{
            if(isExecuted)
            {
                return
            }
            fetchData()
            setIsExecuted(true)
        },[]
    )
    return (

        <div className="w-full bg-white rounded-xl p-6">
            <div className=" flex justify-between pb-4">
                <div className=" text-zinc-900 text-base font-semibold leading-normal">Dernières inscriptions</div>
                <Link to ="/users/data"><div className=" text-right text-orange-600 text-sm font-semibold leading-tight">Voir tout</div></Link>
            </div>
            <div className=" flex-col justify-start items-start inline-flex w-full">
                {
                    users.map(({ firstName,lastName,img, color,date,percent, money }) => {
                        return (
                            <div className="w-full px-6 py-3 bg-white border-b border-neutral-200 justify-start items-center gap-4 inline-flex">
                                <div className="grow shrink basis-0 h-11 justify-start items-center gap-4 flex">
                                    <img src={img} className='w-12 h-12 rounded-full' alt="" />
                                    <div className="text-zinc-900 text-base font-medium leading-normal">{firstName} {lastName}</div>
                                </div>
                                <div className="w-[92px] flex-col justify-start items-start flex">
                                    <div className="text-right text-zinc-900 text-base font-medium leading-normal">{"Date"}</div>
                                    <div className={`${color} text-xs font-normal`}>{getDateTime(date)}</div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Staff

// shahribek