import React,{useEffect,useState} from 'react'
import Line from '../../components/charts/linechart/line'
import Cards from '../../components/admin-sale-card/cards'
import Options from '../../components/options/options'
import Bar from '../../components/charts/bar-graph/graph'
import { dateCards } from '../../dates/jummy'
import { BarChart } from '@mui/x-charts/BarChart';
import Staff from '../staff-report/staff'

const Main = () => {
    const [formations,setFormations] = useState(0)
    const [articles,setArticle] = useState(0)
    const [missions,setMissions] = useState(0)
    const [activities,setActivities] = useState(0)
    const [admin,setAdmin] = useState(1)
    const [benevoles,setBenevoles] = useState(1)
    const [users,setUsers] = useState(1)
    return (
        <div className="p-6 mb-6 bg-slate-50 min-h-screen">
            <div className=""><Cards onAdmin = {setAdmin} onBenevoles = {setBenevoles} onUsers = {setUsers} onFormations = {setFormations} onArticles = {setArticle} onMissions = {setMissions} onActivities = {setActivities} /></div>
            <div className="grid gap-2 grid-cols-1 lg:grid-cols-2 ">
                <div className=" p-6  my-4  bg-white rounded-xl">
                    <div className="ext-zinc-900 text-base font-medium leading-normal mb-8">Satistiques</div>
                    <div className=" justify-start items-start gap-2 flex flex-wrap">
             
                    <BarChart
      width={500}
      height={300}
      series={[
        { data: [formations,articles,missions,activities], label: '', id: 'pvId', stack: 'total' },
        
      ]}
      xAxis={[{ data: ["Formations","Articles","Missions","Activités"], scaleType: 'band' }]}
    />

                    </div>
                </div>
                <div className=" p-6 my-4 bg-white rounded-xl">
                <div className="ext-zinc-900 text-base font-medium leading-normal mb-8">Votre communauté</div>

                    <Bar users = {users} benevoles = {benevoles} admin ={admin} />
                </div>

            </div>
            <div className="w-full my-6">
        <Staff  />
      </div>
        </div >
    )
}

export default Main