import React,{useEffect, useState} from 'react'
import { DataGrid } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { MenuItem } from '@mui/material';
import { MdOutlineAddPhotoAlternate } from "react-icons/md";
import { getDownloadURL, uploadBytesResumable,ref, deleteObject } from 'firebase/storage'
import { storage } from '../../firebase.config';
import {doc,getDocs,Timestamp, orderBy,setDoc,where,collection,query} from "firebase/firestore"
import { firestore,auth } from '../../firebase.config';
import { getAllItems } from '../../utils/firebaseFunctions';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import { createUserWithEmailAndPassword,sendEmailVerification,signInWithPopup, signOut } from "firebase/auth";
import { useNavigate} from 'react-router-dom';
// Firebase237Firebase237*/
const AddUser = () => {
   const history = useNavigate
   const [isLoading,setIsLoading] = useState(false)
   const [firstName,setFirstName] = useState("")
   const [lastName,setLastName] = useState("")
   const [email,setEmail] = useState("")
   const [isExecuted,setIsExecuted] = useState("")
   const [contact,setContact] = useState("")
   const [address,setAddress] = useState("")
   const [password,setPassword] = useState("")
   const [statusv,setStatus] = useState("")
   const [cpassword,setCPassword] = useState("")
   
   const getItemByEmail = async () =>{
    
    const q = query(collection(firestore, "users"), where("email", "==", email));
    const table = []
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      table.push(doc.data())
    });
    alert(JSON.stringify(table[0]))
    return table[0]
}
   const sendVerificationEmail = () => {
    const user = auth.currentUser
    sendEmailVerification(user)
  }
   const signUp = async () => {
    try {
     let isAgreed = true
        await createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) =>{
        sendVerificationEmail()
        
        auth.signOut();
          // send verification mail.
        alert("Félicitation, le compte a été créé et un message de confirmation a été envoyé dans la boite email de l'utilisateur!")
      }).catch(
        (error)=>{
            alert(error)
            isAgreed =false
        }
      )
      if(!isAgreed)
      {
        return
      }
      await fetch(`https://mail-sender-indol.vercel.app/email/sendEmail`,{
        method:"POST",
        body:JSON.stringify({
          subject:`Hello ${firstName+" "+lastName} we are table montreal-Afrique`,
          email:email,
          message:`your account has been created with the email ${email} and password ${password} a validation link has been sent to you`
        }),
        headers:{
          Accept:"application/json",
          "Content-Type":"application/json"
        }
      }).then(
        (res)=>{

            window.location.reload()
    

        }
      ) 
     
    } catch (err){
      console.error(err);
    }
  };
   const saveDetails = async () =>{
    try{
             setIsLoading(true)
             if(!address||!firstName||!lastName||!email||!contact||!password||!statusv){
                alert("Veillez remplir tous les champs")
                setIsLoading(false)
                return
             }
                
             if(password.length < 6){
                alert("le mots de passe doit avoir au moins 6 caractères")
                setIsLoading(false)
                return
            }
        
            if(password !== cpassword){
                alert("les deux champs du mot de passe doivent être exacte!")
                setIsLoading(false)
                return
            }   
            const u = await getItemByEmail()
            alert(JSON.stringify(u))
            if(u  !== undefined){
                alert("L'utilisateur ayant l'email "+email+" existe déjà!")
                setIsLoading(false)
                return
            }          
                 // const images = JSON.parse(localStorage.getItem("images"))
                const data = {
                    firstName,
                    lastName,
                    email,
                    address,
                    contact,
                    email,
                    img:"https://img.freepik.com/free-photo/user-profile-front-side_187299-39595.jpg?size=626&ext=jpg",
                    lastName,
                    status:statusv,
                    date:new Date().getTime()
                }
                try{
                    // for(let i = 0;i<images.length;i++)
                    // {
                    //     await setDoc(doc(firestore,"images",`${Date.now()}`),images[i],{merge:true})
                    // }
                    await setDoc(doc(firestore,"users",`${Date.now()}`),data,{merge:true}).catch((error)=>{alert(error)})
                
                    alert("Enregistrement terminé")
                }
                catch(error)
                {  
                setIsLoading(false)

                    alert(error)
                }
                setIsLoading(false)

                signUp()
                setIsLoading(false)

    }
    catch(error)
    {
    alert("Error")
    }
}

   useEffect(
    ()=>{
        if(isExecuted)
        {
            return
        }
    
        setIsExecuted(true)
    },[]
   )
    return (
        <div className="p-6 mb-6 bg-slate-50 min-h-screen">
                    <div className="ext-zinc-900 text-3xl ml-10 font-medium leading-normal mb-8">Ajouter un utilisateur</div>
                    <div className='grid grid-cols-1 gap-5'>
                        <div className='rounded-lg gap-2 bg-white p-10'>
                        <TextField id="title" onChange={(e)=>setFirstName(e.target.value)} value={firstName} label="Nom(s)*" className='w-full mb-5' variant="outlined" />
                        <div className='p-2'></div>
                        <TextField onChange={(e)=>setLastName(e.target.value)} value={lastName} id="description" label="Prénom(s)*"  className='w-full' variant="outlined" />
                        <div className='p-2'></div>
                        <TextField onChange={(e)=>setAddress(e.target.value)} value={address} id="address" label="Address*"  className='w-full' variant="outlined" />
                        <div className='p-2'></div>
                        <TextField onChange={(e)=>setContact(e.target.value)} value={contact} id="prix en $"  label="Contact*"  className='w-full' variant="outlined" />
                        <div className='p-2'></div>
                        <TextField onChange={(e)=>setEmail(e.target.value)} value={email} id="prix en $"  label="Email*"  className='w-full' variant="outlined" />
                        <div className='p-2'></div>
                        <TextField onChange={(e)=>setPassword(e.target.value)} value={password} id="prix en $"  label="Mot de passe*"  className='w-full' variant="outlined" />
                        <div className='p-2'></div>
                        <TextField onChange={(e)=>setCPassword(e.target.value)} value={cpassword} id="prix en $"  label="Confirmer le mot de passe*"  className='w-full' variant="outlined" />
                        <div className='p-2'></div>
                                                      
                        <TextField
          id="outlined-select-currency"
          select
          className='w-full'
          label="Role"
          defaultValue="EUR"
          helperText="Veillez choisir un nouveau role"
          onChange={(e)=>setStatus(e.target.value)}
          value = {statusv} 
        >
          {["utilisateur","admin","bénévole"].map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField> 
                        <div style = {{width:"600px"}}>

        </div>
                        
                        </div>
                    
                    </div>
                    <div className='p-2'></div>
                    <Button onClick={saveDetails} disabled ={isLoading} variant='contained'>Enregistrer</Button>

        </div >
    )
}

export default AddUser