import React,{useState,useEffect} from 'react'
import { DataGrid } from '@mui/x-data-grid';
import { getAllItems } from '../../utils/firebaseFunctions';
import UpdateArticle from '../update/UpdateArticle';
import {doc,getDocs,orderBy,setDoc,collection,query,where,deleteDoc, updateDoc,} from "firebase/firestore"
import { firestore } from '../../firebase.config';
import UpdateUser from '../update/updateUser';
import { useNavigate } from 'react-router-dom';
const MainUsers = () => {
    const [isExecuted,setExecuted] = useState(false)
    const [users,setUsers] = useState([])
    const [article,setArticle] = useState()
    const [updateDateMode,setUpdateMode] = useState(false)
    const history = useNavigate()
    const fetchData = async () =>{
        await  getAllItems("users","date","desc").then(
             (data) =>{
                 setUsers(data) 
             }
          
         )
      }
    const modeUpdate = async (id) =>{
        setUpdateMode(true)
        localStorage.setItem("ASYNC_ID",JSON.stringify(id))
    }

    const addMission = async (id) =>{
      history("/users/add-mission")
      localStorage.setItem("ASYNC_ID",JSON.stringify(id))
  }
    const columns = [
        { field: 'id', headerName: 'ID', width: 70,renderCell:({row:{date}})=>{
            return ( <h4>#{date}</h4>)
        } },
        {
           field:"cover",headerName:"Profil",with:200,renderCell:(params)=>{
            return (<img className ="w-10 h-10 rounded-full" src = {params.row.img} />)
           }
        },
        
        { field: 'Nom', headerName: 'Nom(s) & Prénom(s)', width: 130,renderCell:(params)=>{return <h2>{params.row.firstName} {params.row.lastName}</h2>} },
        { field: 'contact', headerName: 'Contact', width: 100 },
        {
          field: 'address',
          headerName: 'Adresse',
          width: 100,
        },
        {
          field: 'status',
          headerName: 'Statut',
          width: 100,
        },
        {
          field: 'Actions',
          headerName: 'Actions',
          sortable: false,
          flex:1,
          renderCell: (params) =>{
            return <div className='flex gap-2 justify-between items-center'><button onClick={()=>modeUpdate(params.row.date)} className='text-blue-500 bg-blue-100 px-3 py-2 rounded-md'>Role</button> <button onClick={()=>deleteById(params.row.date)} className='text-red-500 bg-red-100 px-3 py-2 rounded-md'>Supprimer</button> <button onClick={()=>addMission(params.row.date)} className='text-purple-500 bg-purple-100 px-3 py-2 rounded-md'>Missions</button> </div>
        }, 
    }
      ];

      const deleteById = async (id) =>{
        const q = query(collection(firestore, "users"), where("date", "==", id));
        const table = []
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          table.push(doc.id)
        });
        var answer = window.confirm("Voulez vous vraiment supprimer?");
        if (answer) {
            await deleteDoc(doc(firestore,"users",table[0]))
            alert("suppression terminée")
            fetchData()
        }
        else {
        //some code
        }
      }
    useEffect(
        ()=>{
        if(isExecuted)
        {
            return
        }
        fetchData()
        
        },[]
    )
    if(updateDateMode){
        return <UpdateUser onUpdateArticle={setUpdateMode}/>
    }

    return (
        <div className="p-6 mb-6 bg-slate-50 min-h-screen">
                    <div className="ext-zinc-900 text-3xl font-medium leading-normal mb-8">Utilisateurs</div>
                    <DataGrid
        rows={users}
        columns={columns}
      
        getRowId={(row) => row.date}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[10, 25, 50, 100]}

        checkboxSelection
      />


        </div >
    )
}

export default MainUsers