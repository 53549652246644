import React,{useEffect, useState} from 'react'
import { DataGrid } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { MenuItem } from '@mui/material';
import { MdOutlineAddPhotoAlternate } from "react-icons/md";
import { getDownloadURL, uploadBytesResumable,ref, deleteObject } from 'firebase/storage'
import { storage } from '../../firebase.config';
import {doc,getDocs,where,updateDoc, Timestamp, orderBy,setDoc,collection,query} from "firebase/firestore"
import { firestore } from '../../firebase.config';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
// Firebase237Firebase237*/
const UpdateMission = ({onUpdateArticle}) => {
   const [isLoading,setIsLoading] = useState(false)
   const [title,setTitle] = useState("")
   const [description,setDescription] = useState("")
   const [status,setStatus] = useState("")
   const [isExecuted,setIsExecuted] = useState(false)
   const [starting,setStarting] = useState(new Date())
   const [address,setAddress] = useState("")
   const [price,setPrice] = useState(0)
   
   const getData = async () =>{
    const q = query(collection(firestore, "missions"), where("date", "==", JSON.parse(localStorage.getItem("ASYNC_ID"))));
    const table = []
    let data = {}
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      data = doc.data()
      table.push(doc.id)
    });

    setTitle(data.title)
    setDescription(data.description)
    setStatus(data.status)
    setPrice(data.price)
    setAddress(data.address)
    setStarting(new Date(data.starting))
    setStatus(data.status)
    return table[0]
  }

  const updateData = async () =>{
    if(!title || !description || !status|| !price || !status)
    {
      alert("Veillez remplir tous les champs avant d'enregistrer")
    }
    else{
      let u = await getData()
      const ref = doc(firestore,"missions",u)
      await updateDoc(ref,{starting:starting.getTime(),price,address,title,status,description}).then(()=>{
         alert("Modification terminé")
         localStorage.removeItem("ASYNC_ID")
         onUpdateArticle(false)
      }).catch(
        (err)=>{
          console.log(err)
          alert(err)
        }
      )
   
    
    }
  }

const cancelUpdate = () =>{
    onUpdateArticle(false)
    localStorage.removeItem("UPDATED_IMAGE")
}
   useEffect(
    ()=>{
        if(isExecuted)
        {
            return
        }
   
        getData()
        setIsExecuted(true)
    },[]
   )
    return (
        <div className="p-6 mb-6 bg-slate-50 min-h-screen">
                    <div className="ext-zinc-900 text-3xl font-medium leading-normal mb-8">Modifier la formation</div>
                    <div className='grid grid-cols-1 gap-5'>
                        <div className='rounded-lg gap-2 bg-white p-10'>
                        <TextField id="title" onChange={(e)=>setTitle(e.target.value)} value={title} label="Titre de l'article*" className='w-full mb-5' variant="outlined" />
                        <div className='p-2'></div>
                        <TextField onChange={(e)=>setDescription(e.target.value)} value={description} id="description" label="Description*" minRows={5} multiline className='w-full' variant="outlined" />
                        <div className='p-2'></div>
                        <TextField onChange={(e)=>setAddress(e.target.value)} value={address} id="address" label="Address*"  className='w-full' variant="outlined" />
                        <div className='p-2'></div>
                        <TextField onChange={(e)=>setPrice(e.target.value)} value={price} id="prix en $" type="number" label="Prix*"  className='w-full' variant="outlined" />
                        <div className='p-2'></div>
                        <div style = {{width:"600px",marginBottom:"20px"}}>
            <p>Date de lancement</p>
            <DateTimePicker  onChange={setStarting} value={starting} />
        </div>
    
                        </div>
                       
                    </div>
                   <div className='p-2'></div>
                   <Button variant ="contained" onClick={updateData}>Modifier</Button>
        </div >
    )
}

export default UpdateMission