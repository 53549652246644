import React,{useState,useEffect} from 'react'
import { DataGrid } from '@mui/x-data-grid';
import { FaPlay } from "react-icons/fa";
import { getAllItems, getDateTime } from '../../utils/firebaseFunctions';
import UpdateArticle from '../update/UpdateArticle';
import {doc,getDocs,orderBy,setDoc,collection,query,where,deleteDoc, updateDoc,} from "firebase/firestore"
import { firestore } from '../../firebase.config';
import UpdateActivity from '../update/UpdateActivity';
import UpdateFormation from '../update/UpdateFormation';
import { useNavigate } from 'react-router-dom';
import { MdVideoChat } from 'react-icons/md';


const MainFormationsBenevoles = () => {
    const [isExecuted,setExecuted] = useState(false)
    const [activities,setActivities] = useState([])
    const [trainings,setTrainings] = useState([])
    const [article,setArticle] = useState()
    const [updateDateMode,setUpdateMode] = useState(false)
    const [chapters,setChapters] = useState([])   
    const getUser =  (id) =>{
      const q = query(collection(firestore, "trainings"),where("userId", "==",JSON.parse(localStorage.getItem("USER"))["date"]),where("trainingId","==",id));
      const table = []
      const querySnapshot =  getDocs(q);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        table.push(doc.data())
      });
      return table[0].chapters
  }
    const getTrain = async (id) =>{
       
      const q = query(collection(firestore, "trainings"),where("trainingId", "==", id));
      const table = []
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        table.push(doc.data())
      });
      return table[0]
  } 
    const fetchData = async () =>{
        await  getAllItems("formations","date","desc").then(
             (data) =>{
                 setActivities(data) 
             }
          
         )
         await  getAllItems("chapters","date","asc").then(
          (data) =>{
              setChapters(data) 
          }
       
      )

      await  getAllItems("trainings","date","asc").then(
        (data) =>{
            setTrainings(data) 
        }
     
    )
      }
    const history = useNavigate()
    const addChapter = (id) =>{
      localStorage.setItem("ASYNC_ID",JSON.stringify(id))
      history("/formations/chapter")
    }
    const modeUpdate = async (id) =>{
        setUpdateMode(true)
        localStorage.setItem("ASYNC_ID",JSON.stringify(id))
    }
    const goToLesson = async (id,length) =>{
      if(length === 0)
      {
        alert("this lesson does not yet contain a chapter")
        return
      }
      const t = await getTrain(id)
      if(t){
          localStorage.setItem("trainingId",JSON.stringify(t.date))  
      }
      else{
      
          const data = {
          date:new Date().getTime(),
          trainingId:id,
          userId:JSON.parse(localStorage.getItem("USER"))["date"],
          chapters:0,
          status:"In Progress"
           }
   
          await setDoc(doc(firestore,"trainings",`${Date.now()}`),data,{merge:true}).catch((error)=>{alert(error)}).then(
            (data)=>{
              
            }
          )
          localStorage.setItem("trainingId",JSON.stringify(data.date))  

          
      
      }
  
      history("/formations-benevoles/lesson")
      localStorage.setItem("CURRENT_TRAINING",JSON.stringify(id))
    }

      const deleteById = async (id) =>{
        const q = query(collection(firestore, "formations"), where("date", "==", id));
        const table = []
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          table.push(doc.id)
        });
        var answer = window.confirm("Voulez vous vraiment supprimer?");
        if (answer) {
            await deleteDoc(doc(firestore,"formations",table[0]))
            alert("suppression terminée")
            fetchData()
        }
        else {
        //some code
        }
      }
    useEffect(
        ()=>{
        if(isExecuted)
        {
            return
        }
        fetchData()
        setExecuted(true)
        },[]
    )
    if(updateDateMode){
        return <UpdateFormation onUpdateArticle={setUpdateMode}/>
    }

    return (
        <div className="p-6 mb-6 bg-slate-50 min-h-screen">
                    <div className="ext-zinc-900 text-3xl font-medium leading-normal mb-8">Formations</div>
                    {/* <DataGrid
        rows={activities}
        columns={columns}
        getRowId={(row) => row.date}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection
      /> */}
      <div className='grid grid-cols-3 gap-5'>
        {
          activities.map(
            (data)=>{
              return <div className='p-5 border-2 rounded-md'>
                <div className='mb-2'>
                  <img className='w-full h-[200px] rounded-md object-cover cursor-pointer hover:scale-[1.1] transition-all' src ={data.img}/>
                </div>
                <h2 className='font-bold mb-1'>{data.title}</h2>
                <h3 className='text-gray-800'>{chapters.filter(d=>d.formationId === data.date).length} chapters</h3>
                <div className='flex gap-3 items-center'><h3>Success rate {
                      (trainings.filter(d=>d.userId === JSON.parse(localStorage.getItem("USER"))["date"] && d.trainingId === data.date).length>0? (trainings.filter(d=>d.userId === JSON.parse(localStorage.getItem("USER"))["date"] && d.trainingId === data.date)[0]?.chapters*100)/chapters.filter(d=>d.formationId === data.date).length:0)}%</h3> <div onClick={()=>goToLesson(data.date,chapters.filter(d=>d.formationId === data.date).length)} className='w-[40px] h-[40px] flex justify-center items-center rounded-full bg-orange-500 shadow-lg shadow-orange-200 hover:scale-[1.1] hover:shadow-xl hover:shadow-orange-300 cursor-pointer transition-all'><FaPlay className='text-white'/></div> </div>
              </div>
            }
          )
        }

      </div>


        </div >
    )
}

export default MainFormationsBenevoles