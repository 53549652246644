import React,{useEffect, useState} from 'react'
import { DataGrid } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { MenuItem } from '@mui/material';
import { MdOutlineAddPhotoAlternate } from "react-icons/md";
import { getDownloadURL, uploadBytesResumable,ref, deleteObject } from 'firebase/storage'
import { storage } from '../../firebase.config';
import {doc,getDocs,where,updateDoc, Timestamp, orderBy,setDoc,collection,query} from "firebase/firestore"
import { firestore } from '../../firebase.config';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
// Firebase237Firebase237*/
const UpdateUser = ({onUpdateArticle}) => {
   const [isLoading,setIsLoading] = useState(false)
   const [firstName,setFirstName] = useState("")
   const [lastName,setLastName] = useState("")
   const [status,setStatus] = useState("")
   const [isExecuted,setIsExecuted] = useState(false)
   const [address,setAddress] = useState("")
   const [contact,setContact] = useState(0)
   const [image,setImage] = useState("")
   const getData = async () =>{
    const q = query(collection(firestore, "users"), where("date", "==", JSON.parse(localStorage.getItem("ASYNC_ID"))));
    const table = []
    let data = {}
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      data = doc.data()
      table.push(doc.id)
    });

    setFirstName(data.firstName)
    setImage(data.img)
    setLastName(data.lastName)
    setStatus(data.status)
    setContact(data.contact)
    setAddress(data.address)
    return table[0]
  }

  const updateData = async () =>{

      let u = await getData()
      const ref = doc(firestore,"users",u)
      await updateDoc(ref,{status}).then(()=>{
         alert("Opération terminé")
         localStorage.removeItem("ASYNC_ID")
         onUpdateArticle(false)
      }).catch(
        (err)=>{
          console.log(err)
          alert(err)
        }
      )
   
    
    
  }

const cancelUpdate = () =>{
    onUpdateArticle(false)
    localStorage.removeItem("UPDATED_IMAGE")
}
   useEffect(
    ()=>{
        if(isExecuted)
        {
            return
        }
   
        getData()
        setIsExecuted(true)
    },[]
   )
    return (
        <div className="p-6 mb-6 bg-slate-50 min-h-screen">
                    <div className="ext-zinc-900 text-3xl font-medium leading-normal mb-8">Modifier le role de l'utilisateur</div>
                    <div className='grid grid-cols-2 gap-5'>
                        <div className='flex  rounded-lg gap-2 items-center justify-between bg-white p-10'>
                            <div>
                                <img src = {image} className='w-40 h-40'/>      
                            </div>
                            <div className='flex flex-col items-start'>
                                <p className='flex gap-3 text-[#666]'><span className=' text-black font-bold'>Nom(s): </span>{firstName}</p>
                                <p className='flex gap-3 text-[#666]'><span className=' text-black font-bold'>Prénom(s): </span>{lastName}</p>
                                <p className='flex gap-3 text-[#666]'><span className='text-black font-bold'>Statut: </span>{status}</p>
                                <p className='flex gap-3 text-[#666]'><span className='text-black font-bold'>Address: </span>{address}</p>
                                <p className='flex gap-3 text-[#666]'><span className='text-black font-bold'>Contact: </span>{contact}</p>


                            </div>
                        </div>
                        <div className='rounded-lg gap-2 bg-white p-10'>
                
                                    
                        <TextField
          id="outlined-select-currency"
          select
          className='w-full'
          label="Role"
          defaultValue="EUR"
          helperText="Veillez choisir un nouveau role"
          onChange={(e)=>setStatus(e.target.value)}
          value = {status} 
        >
          {["utilisateur","admin","bénévole"].map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>        
                        <div className='flex gap-2 mt-5'>
                   <Button variant ="contained" onClick={updateData}>Modifier</Button>
                    <Button onClick={cancelUpdate}>Annuler</Button>
                   </div>
                        </div>
                       
                    </div>
                   <div className='p-2'></div>
                
        </div >
    )
}

export default UpdateUser