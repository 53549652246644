import React,{useEffect,useState} from 'react'
import Line from '../../components/charts/linechart/line'
import Cards from '../../components/admin-sale-card/cards'
import Options from '../../components/options/options'
import Bar from '../../components/charts/bar-graph/graph'
import { dateCards } from '../../dates/jummy'
import { BarChart } from '@mui/x-charts/BarChart';
import Staff from '../staff-report/staff'
import CardsB from '../admin-sale-card/CardsBenevole'
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom'

const BenevoleMain = () => {
    const [formations,setFormations] = useState(0)
    const [articles,setArticle] = useState(0)
    const [missions,setMissions] = useState([])
    const [activities,setActivities] = useState(0)
    const [admin,setAdmin] = useState(1)
    const [benevoles,setBenevoles] = useState(1)
    const [users,setUsers] = useState(1)
    const history = useNavigate()
    const goToRapport = (id) =>{
        localStorage.setItem("ASYNC_ID",JSON.stringify(id))
        history("/missions-benevoles/rapports")
      }
    const columns = [
        { field: 'id', headerName: 'ID', width: 70,renderCell:({row:{date}})=>{
            return ( <h4>#{date}</h4>)
        } },
        { field: 'title', headerName: 'Titre', width: 130 },
        { field: 'status', headerName: 'Status', width: 130, renderCell:({row:{status}})=>{
            let msg = status === "En cours"? "In progress":status === "Terminé"? "Finished":"Pending"
            return ( <h4>{msg}</h4>)
        }},
         {
           field:"description",headerName:"Description",width:300
         },
    
        {
          field: 'Actions',
          headerName: 'Actions',
          sortable: false,
          flex:1,
          renderCell: ({row:{date}}) =>{
            return <div className='flex gap-2 justify-between items-center'><button onClick={()=>goToRapport(date)}   className='text-blue-500 bg-blue-100 px-3 py-2 rounded-md'>Reports</button>   </div>
        }, 
    }
      ];
    return (
        <div className="p-6 mb-6 bg-slate-50 min-h-screen">
            <div className=""><CardsB onMissions = {setMissions} /></div>
            <div className="mt-6 mb-6 bg-slate-50 min-h-screen">
                    <div className="ext-zinc-900 text-3xl font-medium leading-normal mb-8">Last Missions</div>
                    <DataGrid
        rows={missions}
        columns={columns}
        getRowId={(row) => row.date}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection
      />


        </div >
           
        </div >
    )
}

export default BenevoleMain