import React,{useState,useEffect} from 'react'
import { DataGrid } from '@mui/x-data-grid';
import { getAllItems, getDateTime } from '../../utils/firebaseFunctions';
import UpdateArticle from '../update/UpdateArticle';
import {doc,getDocs,orderBy,setDoc,collection,query,where,deleteDoc, updateDoc,} from "firebase/firestore"
import { firestore } from '../../firebase.config';
import UpdateActivity from '../update/UpdateActivity';
import UpdateFormation from '../update/UpdateFormation';
import UpdateMission from '../update/updateMission';
import { useNavigate } from 'react-router-dom';

const MainMissionB = () => {
    const [isExecuted,setExecuted] = useState(false)
    const [activities,setActivities] = useState([])
    const [article,setArticle] = useState()
    const [updateDateMode,setUpdateMode] = useState(false)
    
    const fetchData = async () =>{
        await  getAllItems("usermission","date","desc").then(
             (data) =>{
                setActivities(data.filter(d=>d.userId === JSON.parse(localStorage.getItem("USER"))["date"]).length)
            
             }
          
         )
      }

      const history = useNavigate()
      const goToRapport = (id) =>{
        localStorage.setItem("ASYNC_ID",JSON.stringify(id))
        history("/missions-benevoles/rapports")
      }
      const columns = [
        { field: 'id', headerName: 'ID', width: 70,renderCell:({row:{date}})=>{
            return ( <h4>#{date}</h4>)
        } },
        { field: 'title', headerName: 'Titre', width: 130 },
        { field: 'status', headerName: 'Status', width: 130, renderCell:({row:{status}})=>{
            let msg = status === "En cours"? "In progress":status === "Terminé"? "Finished":"Pending"
            return ( <h4>{msg}</h4>)
        }},
         {
           field:"description",headerName:"Description",width:300
         },
    
        {
          field: 'Actions',
          headerName: 'Actions',
          sortable: false,
          flex:1,
          renderCell: ({row:{date}}) =>{
            return <div className='flex gap-2 justify-between items-center'><button onClick={()=>goToRapport(date)}  className='text-blue-500 bg-blue-100 px-3 py-2 rounded-md'>Reports</button>   </div>
        }, 
    }
      ];
      const deleteById = async (id) =>{
        const q = query(collection(firestore, "missions"), where("date", "==", id));
        const table = []
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          table.push(doc.id)
        });
        var answer = window.confirm("Voulez vous vraiment supprimer?");
        if (answer) {
            await deleteDoc(doc(firestore,"missions",table[0]))
            alert("suppression terminée")
            fetchData()
        }
        else {
        //some code
        }
      }
    useEffect(
        ()=>{
        if(isExecuted)
        {
            return
        }
        fetchData()
        
        },[]
    )
    if(updateDateMode){
        return <UpdateMission onUpdateArticle={setUpdateMode}/>
    }

    return (
        <div className="p-6 mb-6 bg-slate-50 min-h-screen">
                    <div className="ext-zinc-900 text-3xl font-medium leading-normal mb-8">Missions</div>
                    <DataGrid
        rows={activities}
        columns={columns}
        getRowId={(row) => row.date}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection
      />


        </div >
    )
}

export default MainMissionB