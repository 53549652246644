import React, { useState } from 'react'
import { PieChart } from '@mui/x-charts/PieChart';


const Bar = ({users,admin,benevoles}) => {

    let [modalCountries, setModalCountries] = useState(false)
    let [modalCities, setModalCities] = useState(false)
    let [cityArray, setCityArray] = useState([{ city: 'not found' }])
    let [changeCountry, setChangeCountry] = useState('Select Country')
    let [changeCity, setChangeCity] = useState('Cities')

    let SelectedCountry = (country) => {
        setChangeCountry(country)
        setModalCountries(false)
    }
    let SelectedCity = (City) => {
        setChangeCity(City)
        setModalCities(false)
    }
    let toggleModalCountries = () => {
        setModalCountries((prev) => {
            return !prev
        })
        setModalCities(false)
    }
    let toggleModalCities = () => {
        setModalCities((prev) => {
            return !prev
        })
        setModalCountries(false)
    }
    return (
        <div className=' w-full'>
    <PieChart
      series={[
        {
          data: [
            { id: 0, value: admin, label: 'Administrateurs' },
            { id: 1, value: benevoles, label: 'Bénévoles' },
            { id: 2, value: users, label: 'Utilisateurs' },
          ],
        },
      ]}
      width={450}
      height={200}
    />
        </div>
    )
}

export default Bar