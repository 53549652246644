import React,{useEffect, useState} from 'react'
import { DataGrid } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { MenuItem } from '@mui/material';
import { MdOutlineAddPhotoAlternate } from "react-icons/md";
import { getDownloadURL, uploadBytesResumable,ref, deleteObject } from 'firebase/storage'
import { storage } from '../../firebase.config';
import {doc,getDocs,Timestamp, orderBy,setDoc,collection,query} from "firebase/firestore"
import { firestore } from '../../firebase.config';
// Firebase237Firebase237*/
const AddCategory = () => {
   const [isLoading,setIsLoading] = useState(false)

   const [name,setName] = useState("")

   const saveDetails = async () =>{
    
    try{
        setIsLoading(true)
          
             if(!name){
                alert("Veillez remplir tous les champs")
                return
             }
                
                // const images = JSON.parse(localStorage.getItem("images"))
                const data = {
                    name,
                    date:new Date().getTime(),
                    
                }
                try{
                    // for(let i = 0;i<images.length;i++)
                    // {
                    //     await setDoc(doc(firestore,"images",`${Date.now()}`),images[i],{merge:true})
                    // }
                    await setDoc(doc(firestore,"cb",`${Date.now()}`),data,{merge:true}).then(
                        (data)=>{
                            alert("Opération terminée!")
                        }
                    )
                     setName("")
                }
                catch(error)
                {
                    alert(error)
                }
                setIsLoading(false)
    }
    catch(error)
    {
    alert("Error")
    }
}

   useEffect(
    ()=>{

    },[]
   )
    return (
        <div className="p-6 mb-6 bg-slate-50 min-h-screen">
                    <div className="ext-zinc-900 text-3xl font-medium leading-normal mb-8">Ajouter une catégorie d'article</div>
                    <div className='grid grid-cols-1 gap-5'>
                        <div className='rounded-lg gap-2 bg-white p-10'>
                        <TextField id="title" onChange={(e)=>setName(e.target.value)} value={name} label="libellé*" className='w-full mb-5' variant="outlined" />
                        <div className='p-2'></div>
                        <Button disabled = {isLoading} onClick={saveDetails} className='mt-5' variant='contained'>Enregistrer</Button>
                       
                        
               
                        </div>

                    </div>

        </div >
    )
}

export default AddCategory