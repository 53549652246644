import React,{useEffect, useState} from 'react'
import { DataGrid } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { MenuItem } from '@mui/material';
import { MdOutlineAddPhotoAlternate } from "react-icons/md";
import { getDownloadURL, uploadBytesResumable,ref, deleteObject } from 'firebase/storage'
import { storage } from '../../firebase.config';
import {doc,getDocs,Timestamp, orderBy,setDoc,collection,query} from "firebase/firestore"
import { firestore } from '../../firebase.config';
import { getAllItems } from '../../utils/firebaseFunctions';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
// Firebase237Firebase237*/
const AddMission = () => {
   const [isLoading,setIsLoading] = useState(false)
   const [title,setTitle] = useState("")
   const [description,setDescription] = useState("")
   const [isExecuted,setIsExecuted] = useState("")
   const [starting,setStarting] = useState(new Date())
   const [address,setAddress] = useState("")
   const [price,setPrice] = useState(0)

   const saveDetails = async () =>{
    try{
             setIsLoading(true)
             if(!address||!price||!description||!title){
                alert("Veillez remplir tous les champs")
                return
             }
                
                
                // const images = JSON.parse(localStorage.getItem("images"))
                const data = {
                    title,
                    description,
                    date:new Date().getTime(),
                    starting:starting.getTime(), 
                    address,
                    status:"En cours",
                    price
                }
                try{
                    // for(let i = 0;i<images.length;i++)
                    // {
                    //     await setDoc(doc(firestore,"images",`${Date.now()}`),images[i],{merge:true})
                    // }
                    await setDoc(doc(firestore,"missions",`${Date.now()}`),data,{merge:true}).catch((error)=>{alert(error)})
                    setDescription("")
                    setStarting(null)
                    setAddress("")
                    setTitle("")
                    setPrice(0)
                    alert("Enregistrement terminé")
                }
                catch(error)
                {
                    alert(error)
                }
                setIsLoading(false)
    }
    catch(error)
    {
    alert("Error")
    }
}

   useEffect(
    ()=>{
        if(isExecuted)
        {
            return
        }
    
        setIsExecuted(true)
    },[]
   )
    return (
        <div className="p-6 mb-6 bg-slate-50 min-h-screen">
                    <div className="ext-zinc-900 text-3xl font-medium leading-normal mb-8">Ajouter une mission</div>
                    <div className='grid grid-cols-1 gap-5'>
                        <div className='rounded-lg gap-2 bg-white p-10'>
                        <TextField id="title" onChange={(e)=>setTitle(e.target.value)} value={title} label="Nom de la mission*" className='w-full mb-5' variant="outlined" />
                        <div className='p-2'></div>
                        <TextField onChange={(e)=>setDescription(e.target.value)} value={description} id="description" label="les attentes*" minRows={5} multiline className='w-full' variant="outlined" />
                        <div className='p-2'></div>
                        <TextField onChange={(e)=>setAddress(e.target.value)} value={address} id="address" label="Address*"  className='w-full' variant="outlined" />
                        <div className='p-2'></div>
                        <TextField onChange={(e)=>setPrice(e.target.value)} value={price} id="prix en $" type="number" label="salaire*"  className='w-full' variant="outlined" />
                        <div className='p-2'></div>
                        <div style = {{width:"600px"}}>
            <p>Date de lancement</p>
            <DateTimePicker  onChange={setStarting} value={starting} />
        </div>
                        
                        </div>
                    
                    </div>
                    <div className='p-2'></div>
                    <Button onClick={saveDetails} disabled ={isLoading} variant='contained'>Enregistrer</Button>

        </div >
    )
}

export default AddMission