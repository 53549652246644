import React,{useContext,useEffect,useState} from 'react'
import Navbar from "../../components/admin-navbar/admin-navbar"
import Sidebar from "../../components/adminn-sidebar/sidebar"
import { Context } from '../../context'
import Main from "../../components/admin-main/main"
import { useParams } from 'react-router-dom'
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { getAllItems, getDateTime } from '../../utils/firebaseFunctions'


export default function Notifications() {
  const {category} = useParams()
  let { state, dispatch } = useContext(Context)
  let [size, setSize] = useState(1000)
  const [isExecuted,setIsExecuted] = useState(false)
  const [notifications,setNotifications] = useState([])
  window.addEventListener('resize', (e) => {
    setSize(e.currentTarget.innerWidth)
  })
  useEffect(() => {
    size < 768 ? dispatch({ type: 'SET_TOGGLE_NAVBAR', payload: false }) : dispatch({ type: 'SET_TOGGLE_NAVBAR', payload: true })
  }, [size])
  const fetchData = async () =>{
    await  getAllItems("notifications","date","desc").then(
         (data) =>{
             setNotifications(data.filter(d=>d.type === "info" && d.access === "admin")) 
         }
      
     )
  }
  useEffect(
    ()=>{
        if(isExecuted)
        {
          return
        }
        fetchData()
        setIsExecuted(true)
    },[]
  )
  return (
    <div className=''>
      <div className=""><Navbar /></div>
      <div className="main text-center max-w-[2300px] mt-[76px] flex flex-1 justify-between">
        <Sidebar active='fa-solid fa-bell'subActive ="Notifications" />
        <div className={`flex justify-center main ${state.toggle ? ` ${state.toggleNavbar ? 'md:ml-[310px]' : 'ml-0'}` : ` ${state.toggleNavbar ? 'md:ml-[90px]' : 'ml-0'}`} overflow-auto w-full h-full z-10`+" p-20"}>
{
      notifications.length>0 &&  <div className='bg-slate-50 p-10 rounded-xl min-h-[80vh] overflow-scroll'>
            {
              notifications.map(
                (n)=>{
                  return   <Alert severity={n.type} className='mb-4'><div className='flex flex-col gap-1 items-start justify-start'><p style={{textAlign:"start"}}>{n.message}</p><span>{getDateTime(n.date)}</span></div></Alert>
                }
              )
            }       
        </div>}

      {!notifications.length>0 &&   <div className='flex-col  flex items-center jsutify-center'>
            <img src ="/notification.png" className='w-[300px] h-[300px]'/>
            <h1 style = {{letterSpacing:"1.5px"}} className='font-bold text-[#555] '>Aucunes Notifications</h1> 
        </div>}
        
        </div>
      </div>
    </div>
  )
}
