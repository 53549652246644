import React,{useState,useEffect} from 'react'
import { TextField,InputAdornment } from '@mui/material'
import {Button} from '@mui/material'
import {firestore} from "../../firebase.config"
import {doc,getDocs,orderBy,setDoc,collection,query,where,deleteDoc, updateDoc,} from "firebase/firestore"
import { useNavigate } from 'react-router-dom'
import { MdContactPhone, MdMail, MdPassword, MdPerson, MdPerson2, MdPlace } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify';
import { auth } from '../../firebase.config';

import { createUserWithEmailAndPassword,sendEmailVerification,signInWithPopup,getAuth, signOut,fetchSignInMethodsForEmail } from "firebase/auth";

export default function Register() {
 
    const [Password,setPassword] = useState()
    const [CPassword,setCPassword] = useState()
    const [email,setEmail] = useState()
    const [address,setAddress] = useState()
    const [contact,setContact] = useState()
    const [firstName,setFirstName] = useState()
    const [lastName,setLastName] = useState()
    const history = useNavigate()
    const sendVerificationEmail = () => {
        const user = auth.currentUser
        sendEmailVerification(user)
      }
      async function checkEmailExists() {
        try {
          // Récupère les méthodes de connexion associées à cet email
          const signInMethods = await fetchSignInMethodsForEmail(auth, email)
          // Si la liste des méthodes n'est pas vide, c'est que l'email existe déjà
          return signInMethods.length > 0
        } catch (error) {
          // Gérer les erreurs de manière appropriée
          throw error
        }
      }
    const signUp = async () => {
         
  
        if(Password.length < 6){
            alert("the password must be at least 6 digits")
            return
        }

        if(CPassword !== Password){
            alert("please check the password fields")
            return
        }
        try {
          await createUserWithEmailAndPassword(auth, email, Password)
          .then((userCredential) =>{
            sendVerificationEmail()
            
            auth.signOut();
              // send verification mail.
            alert("Congratulations, your account has been created and a confirmation message has been sent to your email box")
          })
            
         history("/login")
        } catch (err){
          console.error(err);
        }
      };
      const saveDetails = async () =>{
      
        if(!email || !Password || !address || !CPassword || !contact || !firstName || !lastName){
          alert("please complete all fields before registering")
          return
      } 
      // if(checkEmailExists()){
      //   alert("email " + email+" already exist")
      //   return
      // }
        try{
              
                 
                    // const images = JSON.parse(localStorage.getItem("images"))
                    const data = {
                        address,
                        contact,
                        email,
                        firstName,
                        lastName,
                        img:"https://img.freepik.com/free-photo/user-profile-front-side_187299-39595.jpg?size=626&ext=jpg",
                        date:new Date().getTime(),
                        status:"bénévole"
                    }
                    try{
                        // for(let i = 0;i<images.length;i++)
                        // {
                        //     await setDoc(doc(firestore,"images",`${Date.now()}`),images[i],{merge:true})
                        // }
                        await setDoc(doc(firestore,"users",`${Date.now()}`),data,{merge:true}).catch((error)=>{alert(error)})
                    
                    }
                    catch(error)
                    {
                        alert(error)
                    }
                    await signUp()
                       

        }
        catch(error)
        {
        
        }
    }
    useEffect(
        ()=>{
          if(!localStorage.getItem("USER")){
            history("/register")
            }
            else if(localStorage.getItem("USER") && JSON.parse(localStorage.getItem("USER"))["status"] === "admin"){
              history("/")     
            }
            else if(localStorage.getItem("USER") && JSON.parse(localStorage.getItem("USER"))["status"] !== "admin"){
            
             history("/") 
            }
        }
    ) 
    return (
    <div className='min-h-screen h-auto p-10 bg-gray-100 grid grid-cols-1 md:grid-cols-2'>
        <div className='p-[45px] h-[100%] w-full bg-white rounded-tl-lg rounded-bl-lg grid-cols-1'>
            <img className=' h-32' src ="logo.png"/>
            <h1 className='font-bold text-[20px] text-start mb-1'>CREATE YOUR ACCOUNT</h1>
            <h3 className='mb-5 text-[#555]'>Welcome, select method to register:</h3>
            <div className='flex gap-5 mb-5'>
                <div className='border-2 border-gray-300 rounded-md  py-2 px-3 flex gap-1 cursor-pointer items-center'><img src ="google.png" className='w-7'/> Google</div>
                <div className='border-2 border-gray-300 rounded-md  py-2 px-3 flex gap-1 cursor-pointer items-center'><img src ="facebook.png" className='w-7'/> Facebook</div>
                
            </div>
            <TextField  InputProps={{startAdornment: (
    <InputAdornment position="end">
      <MdPerson />
    </InputAdornment>
)}} onChange={(e)=>setFirstName(e.target.value)} value={firstName} id="FirstName" label="First Name*"  className='w-full' variant="outlined" />
            <div className='py-2'></div>
            <TextField  InputProps={{startAdornment: (
    <InputAdornment position="end">
      <MdPerson2 />
    </InputAdornment>
)}} onChange={(e)=>setLastName(e.target.value)} value={lastName} id="lastName" label="Last Name*"  className='w-full' variant="outlined" />
            <div className='py-2'></div>
            <TextField  InputProps={{startAdornment: (
    <InputAdornment position="end">
      <MdContactPhone />
    </InputAdornment>
)}} onChange={(e)=>setContact(e.target.value)} value={contact} id="contact" label="Contact*"  className='w-full' variant="outlined" />
            <div className='py-2'></div>
            <TextField  InputProps={{startAdornment: (
    <InputAdornment position="end">
      <MdPlace />
    </InputAdornment>
)}} onChange={(e)=>setAddress(e.target.value)} value={address} id="address" label="Address*"  className='w-full' variant="outlined" />
            <div className='py-2'></div>
     
            <TextField  InputProps={{startAdornment: (
    <InputAdornment position="end">
      <MdMail />
    </InputAdornment>
)}} onChange={(e)=>setEmail(e.target.value)} value={email} id="email" label="Email*"  className='w-full' variant="outlined" />
            <div className='py-2'></div>
  
            <TextField  InputProps={{startAdornment: (
    <InputAdornment position="end">
      <MdPassword />
    </InputAdornment>
)}} onChange={(e)=>setPassword(e.target.value)} value={Password} id="password" label="Password*"  className='w-full' variant="outlined" />
            <div className='py-2'></div>
            <TextField  
            InputProps={{startAdornment: (
                <InputAdornment position="end">
                  <MdPassword />
                </InputAdornment>
            )}}
            onChange={(e)=>setCPassword(e.target.value)} value={CPassword} id="password" label="Confirm Password*"  className='w-full' variant="outlined" />
            <div className='mt-3'></div>
            <Button onClick={()=>saveDetails()} className='w-full' variant="contained">SIGN UP</Button>
            <h3 className='mb-10 text-[#555] mt-5'>Do you have account ? <Link to ="/login"><span className='text-orange-500 cursor-pointer'>Sign In</span></Link></h3>
            
        </div>
        <div style={{backgroundImage:"url(https://images.pexels.com/photos/6348129/pexels-photo-6348129.jpeg?auto=compress&cs=tinysrgb&w=600)",backgroundRepeat:"no-repeat",backgroundSize:"cover",backgroundPosition:"center"}} className='grid-cols-1 h-[100%] bg-orange-50 rounded-tr-lg rounded-br-lg relative'>
            <div className='rounded-tr-lg rounded-br-lg absolute top-0 left-0 right-0 bottom-0 bg-gradient-to-r from-orange-400 opacity-10 '>

            </div>
        </div>
    </div>
  )
}
