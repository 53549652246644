// Import the functions you need from the SDKs you need
import { initializeApp,getApp,getApps } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import {getAuth, GoogleAuthProvider} from 'firebase/auth'

const firebaseConfig = {
  apiKey: "AIzaSyCi3grDYkl5sN2keR_c-beCEH0bqMuoG9U",
  authDomain: "tmatmatma-c5991.firebaseapp.com",
  projectId: "tmatmatma-c5991",
  storageBucket: "tmatmatma-c5991.appspot.com",
  messagingSenderId: "129495728331",
  appId: "1:129495728331:web:7626970d1030524c5fdb4e",
  measurementId: "G-CRVK9FFL6F"
};
// Initialize Firebase


const app = initializeApp(firebaseConfig)
const auth =  getAuth(app);
const firestore = getFirestore(app)
const storage = getStorage(app)

 export {app,firestore,storage,auth}