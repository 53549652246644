import React,{useEffect, useState} from 'react'
import { DataGrid } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { MenuItem } from '@mui/material';
import { MdOutlineAddPhotoAlternate } from "react-icons/md";
import { getDownloadURL, uploadBytesResumable,ref, deleteObject } from 'firebase/storage'
import { storage } from '../../firebase.config';
import {doc,getDocs,where,updateDoc, Timestamp, orderBy,setDoc,collection,query,deleteDoc} from "firebase/firestore"
import { firestore } from '../../firebase.config';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import { getAllItems } from '../../utils/firebaseFunctions';
import AddMission from '../add/AddMission';
import { useNavigate } from 'react-router-dom';
// Firebase237Firebase237*/
const AddUserMission = ({onUpdateArticle}) => {
   const [isLoading,setIsLoading] = useState(false)
   const [data,setData] = useState([])
   const history = useNavigate()
   const [firstName,setFirstName] = useState("")
   const [lastName,setLastName] = useState("")
   const [missions,setMissions] = useState([])
   const [status,setStatus] = useState("")
   const [isExecuted,setIsExecuted] = useState(false)
   const [address,setAddress] = useState("")
   const [contact,setContact] = useState(0)
   const [image,setImage] = useState("")
   const [currentMission,setCurrentMission] = useState()
   const [currentMissionLabel,setCurrentMissionLabel] = useState("")
   const deleteById = async (id) =>{
    const q = query(collection(firestore, "usermission"), where("date", "==", id));
    const table = []
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      table.push(doc.id)
    });
    var answer = window.confirm("Voulez vous vraiment supprimer?");
    if (answer) {
        await deleteDoc(doc(firestore,"usermission",table[0]))
        alert("suppression terminée")
        fetchData()
    }
    else {
    //some code
    }
  }

  const goToRapport = (id) => {
    history("/users/rapports")
    localStorage.setItem("ASYNC_ID",JSON.stringify(id))    } 
   const columns = [
    { field: 'id', headerName: 'ID', width: 70,renderCell:({row:{date}})=>{
        return ( <h4>#{date}</h4>)
    } },
    { field: 'title', headerName: 'Titre', width: 130 },
    { field: 'description', headerName: 'Attentes', width: 130 },
    {
      field: 'address',
      headerName: 'Adresse',
      width: 100,
    },
    {
      field: 'status',
      headerName: 'Statut',
      width: 100,
    },
    {
        field: 'price',
        headerName: 'Prix $',
        width: 100,
      },
    {
      field: 'Actions',
      headerName: 'Actions',
      sortable: false,
      flex:1,
      renderCell: (params) =>{
        return <div className='flex gap-2 justify-between items-center'><button onClick={()=>goToRapport(params.row.date)}  className='text-blue-500 bg-blue-100 px-3 py-2 rounded-md'>Rapports</button> <button onClick={()=>deleteById(params.row.date)} className='text-red-500 bg-red-100 px-3 py-2 rounded-md'>Supprimer</button>  </div>
    }, 
}
  ];
  const fetchData = async () =>{
    await  getAllItems("usermission","date","desc").then(
         (data) =>{
             setData(data) 
         }
      
     )
  }
   const saveMission = async () =>{
    if(!currentMission?.title){
        alert("veillez choisir une mission avant d'enregistrer")
        return
    } 
    try{
        setIsLoading(true)
          
        
                
                // const images = JSON.parse(localStorage.getItem("images"))
                const data = {
                    title:currentMission?.title,
                    address:currentMission?.address,
                    description:currentMission?.description,
                    price:currentMission?.price,
                    starting:currentMission?.starting,
                    username:firstName+" "+lastName,
                    status:"En attente",
                    date:new Date().getTime(),
                    userId:JSON.parse(localStorage.getItem("ASYNC_ID"))
                }
                try{
                    // for(let i = 0;i<images.length;i++)
                    // {
                    //     await setDoc(doc(firestore,"images",`${Date.now()}`),images[i],{merge:true})
                    // }
                    await setDoc(doc(firestore,"usermission",`${Date.now()}`),data,{merge:true}).then(
                        (data)=>{
                            alert("Opération terminée!")
                            fetchData()
                        }
                    )

                    await setDoc(doc(firestore,"notifications",`${Date.now()}`),{type:"info",message:"L'équipe d'administration vient de vous confier une tache:  "+currentMission?.title,access:"user",userId:JSON.parse(localStorage.getItem("ASYNC_ID")),date:new Date().getTime()},{merge:true}).then(
                      (data)=>{
                          
                        
                      }
                  )

                     
                }
                catch(error)
                {
                    alert(error)
                }
                setIsLoading(false)
    }
    catch(error)
    {
    alert("Error")
    }

   }

   const getData = async () =>{
    const q = query(collection(firestore, "users"), where("date", "==", JSON.parse(localStorage.getItem("ASYNC_ID"))));
    const table = []
    let data = {}
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      data = doc.data()
      table.push(doc.id)
    });

    setFirstName(data.firstName)
    setImage(data.img)
    setLastName(data.lastName)
    setStatus(data.status)
    setContact(data.contact)
    setAddress(data.address)
    return table[0]
  }

  const updateData = async () =>{

      let u = await getData()
      const ref = doc(firestore,"users",u)
      await updateDoc(ref,{status}).then(()=>{
         alert("Opération terminé")
         localStorage.removeItem("ASYNC_ID")
         onUpdateArticle(false)
      }).catch(
        (err)=>{
          console.log(err)
          alert(err)
        }
      )
   
    
    
  }
  const fetchMission = async () =>{
    await  getAllItems("missions","date","desc").then(
         (data) =>{
             setMissions(data) 
         }
     )
  }
const cancelUpdate = () =>{
    onUpdateArticle(false)
    localStorage.removeItem("UPDATED_IMAGE")
}
   useEffect(
    ()=>{
        if(isExecuted)
        {
            return
        }
   
        getData()
        fetchMission()
        fetchData()
        setIsExecuted(true)
    },[]
   )
    return (
        <div className="p-6 mb-6 bg-slate-50 min-h-screen">
                    <div className="ext-zinc-900 text-3xl font-medium leading-normal mb-8">Attribuer une nouvelle mission</div>
                    <div className='grid grid-cols-2 gap-5'>
                        <div className='flex  rounded-lg gap-2 items-start justify-between bg-white p-10'>
                            <div>
                                <img src = {image} className='w-40 h-40 rounded-full'/>      
                            </div>
                            <div className='flex flex-col items-start'>
                                <p className='flex gap-3 text-[#666]'><span className=' text-black font-bold'>Nom(s): </span>{firstName}</p>
                                <p className='flex gap-3 text-[#666]'><span className=' text-black font-bold'>Prénom(s): </span>{lastName}</p>
                                <p className='flex gap-3 text-[#666]'><span className='text-black font-bold'>Statut: </span>{status}</p>
                                <p className='flex gap-3 text-[#666]'><span className='text-black font-bold'>Address: </span>{address}</p>
                                <p className='flex gap-3 text-[#666]'><span className='text-black font-bold'>Contact: </span>{contact}</p>
                            </div>
                        </div>
                        <div className='rounded-lg gap-2 bg-white p-10'>
                        <div className='flex flex-col items-start mb-10'>
                                <p className='flex gap-3 text-[#666]'><span className=' text-black font-bold'>Mission(s) an attente(s): </span>{missions.filter(m=>m.status === "En attente").length}</p>
                                <p className='flex gap-3 text-[#666]'><span className=' text-black font-bold'>Mission(s) en cours: </span>{missions.filter(m=>m.status === "En cours").length}</p>
                                <p className='flex gap-3 text-[#666]'><span className='text-black font-bold'>Mission(s) terminée(s): </span>{missions.filter(m=>m.status === "Terminé").length}</p>
                                <p className='flex gap-3 text-[#666]'><span className='text-black font-bold'>Total: </span>{missions.length}</p>
                            </div>
                                    
                        <TextField
          id="outlined-select-currency"
          select
          className='w-full'
          label="Missions"
          defaultValue="EUR"
          helperText="donner une nouvelle mission"
          onChange={(e)=>setCurrentMissionLabel(e.target.value)}
          value = {currentMissionLabel} 
        >
          {missions.map((option) => (
            <MenuItem key={option} value={option.title} onClick = {()=>setCurrentMission(option)}>
              {option.title}
            </MenuItem>
          ))}
        </TextField>        
                        <div className='flex gap-2 mt-5'>
                   <Button variant ="contained" onClick={saveMission}>Confirmer</Button>
    
                   </div>
                        </div>
                       
                    </div>
                   <div className='p-2'></div>
                   <div className="p-6 mb-6 bg-slate-50 min-h-screen">
                    <div className="ext-zinc-900 text-3xl font-medium leading-normal mb-8">Historique des missions</div>
                    <DataGrid
        rows={data}
        columns={columns}
        getRowId={(row) => row.date}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection
      />


        </div >
        </div >
    )
}

export default AddUserMission